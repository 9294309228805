import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './components/page-view/header/header.component';
import { CustomerTypeSelComponent } from './components/page-view/customer-type-sel/customer-type-sel.component';
import { LoginFormComponent } from './components/page-view/login-form/login-form.component';
import { FormsModule } from '@angular/forms';
import { TabKeydownDirective } from './directives/tab-keydown.directive';
import { OtpHardtokenComponent } from './components/page-view/otp-hardtoken/otp-hardtoken.component';
import { KeepMovingDirective } from './directives/keep-moving.directive';
import { HttpClientModule } from '@angular/common/http';
import { ErrorLabelComponent } from './components/page-view/error-label/error-label.component';
import { ErrModalComponent } from './components/err-modal/err-modal.component';
import { SessionService } from './services/store/session.service';
import { Debouncer } from './utils/debouncer';
import { TimeoutService } from './services/timeout.service';
import { CookiesService } from './services/cookies.service';
import { EdgeBandComponent } from './components/page-view/edge-band/edge-band.component';
import { EdgeFooterComponent } from './components/page-view/edge-footer/edge-footer.component';
import { ToolTipDirective } from './directives/tool-tip.directive';
import { LoadcmsService } from './services/loadcms.service';
import { TransmitService } from './transmit/transmit.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { environment } from 'src/environments/environment';
import { TransmitmockService } from './transmit/transmitmock.service';
import { LoginInfoComponent } from './components/login-info/login-info.component';
import { ErrorLabelLockComponent } from './components/page-view/error-label-lock/error-label-lock.component';
import { ChoiceScreenComponent } from './pages/choice-screen/choice-screen.component';
import { IbFooterComponent } from './components/page-view/ib-footer/ib-footer.component';
import { IbHeaderComponent } from './components/page-view/ib-header/ib-header.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserBindComponent } from './components/page-view/browser-bind/browser-bind.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { RouterModule, Routes } from '@angular/router';
import { LandlineOtpComponent } from './components/page-view/landline-otp/landline-otp.component';
import { ToastService } from './services/toaster.service';
import { ToastrModule } from 'ngx-toastr';
import { toastrModuleConfig } from 'src/config/toaster-module.config';
import { ToastComponent } from './components/toast/toast.component';
import { FeatureTogglesService } from './services/feature-toggles.service';
import { ConfigService } from './services/rest/config.service';
import { WINDOW_TOKEN } from './app.injectionTokens';

export function cmsInit(cms : LoadcmsService){
  return ()=>cms.loadcms();
}

export function initConfig(config: ConfigService) {
  return () => Promise.all([
    config.loadFeatureConfigs()
  ]);
}

export const appRoutes: Routes = [];

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HeaderComponent,
        CustomerTypeSelComponent,
        LoginFormComponent,
        TabKeydownDirective,
        OtpHardtokenComponent,
        KeepMovingDirective,
        ErrorLabelComponent,
        ErrModalComponent,
        EdgeBandComponent,
        EdgeFooterComponent,
        ToolTipDirective,
        LoginInfoComponent,
        ErrorLabelLockComponent,
        ChoiceScreenComponent,
        IbFooterComponent,
        IbHeaderComponent,
        BrowserBindComponent,
        SnackbarComponent,
        LandlineOtpComponent,
        ToastComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        MatButtonModule, MatCardModule, MatFormFieldModule, MatExpansionModule, MatDialogModule,
        MatIconModule, MatCheckboxModule, MatTooltipModule, MatSnackBarModule,
        FlexLayoutModule,
        RouterModule.forRoot(appRoutes, {}),
        ToastrModule.forRoot(toastrModuleConfig),
    ],
    providers: [
        SessionService,
        {
            provide: APP_INITIALIZER,
            useFactory: cmsInit,
            multi: true,
            deps: [LoadcmsService]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            multi: true,
            deps: [ConfigService]
        },
        {
            provide: WINDOW_TOKEN,
            useValue: window
        },
        FeatureTogglesService,
        {
            provide: TransmitService,
            useClass: (environment.bdd) ? TransmitmockService : TransmitService
        },
        GoogleAnalyticsService,
        Debouncer,
        TimeoutService,
        CookiesService,
        ToastService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
