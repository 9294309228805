import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-landline-otp',
  templateUrl: './landline-otp.component.html',
  styleUrls: ['../otp-hardtoken/otp-hardtoken.component.less']
})
export class LandlineOtpComponent {
  @ViewChild('inputElement') otp: ElementRef;
  @Output() showMobileOtp = new EventEmitter<boolean>();
  @Input() isLandline: boolean;
  @Input() landlineOtp;
  @Input() landline;
  @Input() showOtp;
  @Input() hideOnBind;
  @Output() submitLandlineOtp = new EventEmitter<string>();
  @Output() callAgain = new EventEmitter<boolean>();
  @Output() reqMobileOtp = new EventEmitter<boolean>();
  otpValue = '';


  onSubmitLandline() {
    this.submitLandlineOtp.emit(this.otpValue);
  }

  onReqCallAgain() {
    this.callAgain.emit(true);
  }

  onReqText() {
    this.reqMobileOtp.emit(true);
  }
}
