export enum CookieConsentCategory {
  StrictlyNecessary = 'C0001',
  Performance = 'C0002',
  Functional = 'C0003',
  Targeting = 'C0004'
}

export const getHeapScript = (heapCode: string) => {
  return `<script type="text/javascript">
  (window.heap = window.heap || []),
          (heap.load = function(e, t) {
            (window.heap.appid = e), (window.heap.config = t = t || {});
            var r = document.createElement('script');
            (r.type = 'text/javascript'), (r.async = !0), (r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js');
            var a = document.getElementsByTagName('script')[0];
            a.parentNode.insertBefore(r, a);
            for (
                    var n = function(e) {
                              return function() {
                                heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                              };
                            },
                            p = [
                              'addEventProperties',
                              'addUserProperties',
                              'clearEventProperties',
                              'identify',
                              'resetIdentity',
                              'removeEventProperty',
                              'setEventProperties',
                              'track',
                              'unsetEventProperty',
                            ],
                            o = 0;
                    o < p.length;
                    o++
            )
              heap[p[o]] = n(p[o]);
          });
  heap.load('${heapCode}');
</script>`;
};

export const injectHeadScript = (document: Document, script: string, scriptId: string) => {
  const range = document.createRange();
  const fragment = range.createContextualFragment(script);
  const scriptElement = document.createElement('script');
  scriptElement.id = scriptId;
  scriptElement.appendChild(fragment);
  document['head'].appendChild(scriptElement);
};
