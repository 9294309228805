<main>
  <div *ngIf="isLandline && showOtp && !hideOnBind" #inputElement class="otp" fxLayout="column" fxLayoutAlign="space-between center"
    fxLayoutGap="10px">
    <mat-card class="ib-otp mar-t-50">
      <div fxLayout="column" fxLayoutAlign="space-around center">
        <mat-icon svgIcon="ib_logo" class="ib_logo"></mat-icon>
        <h1 class="login-title">Welcome to Metro Bank Online Banking</h1>
      </div>
      <div class="login-seperator"></div>
      <div class="ib-otp-form">
        <form #otpForm="ngForm" name="loginForm" novalidate="" autocomplete="off">
          <div class="mar-t-10 mar-b-40">
            <div>
              <div>
                <h2 class="margin-0"><label class="user-label">We are calling you…</label></h2>
                <div class="full-width">
                  <div class="seeds-dialog">
                    We are dialling your landline number ending  {{landline}}.Once you have your code, please enter it below with your password.</div>
                </div>
                <div class="mar-t-10 full-width">
                  <div>
                    <h2 class="margin-0"><label class="user-label">Code</label></h2>
                    <input
                      [class]="(otpForm.submitted && passseed0.invalid)?'ib-otp-value__err ib-otp-value': 'ib-otp-value'"
                      #passseed0="ngModel" type="password" name="password0" size="8" [(ngModel)]="otpValue"
                      minlength="8" maxlength="8" aria-label="Code" required>
                  </div>
                </div>
                <div class="mar-t-10">
                  <span class="mar-t-10 mar-r-10" [class]="counter >= 4?'disable-links':''">
                    <button mat-flat-button class="ib-link-btn button-link" (click)="onReqCallAgain()" aria-label="Re-send Code" type="button" tabindex="0">Call me again</button>
                  </span>
                </div>
                <p *ngIf="otpForm.submitted && (passseed0.invalid)" class="err-msg" role="alert">One Time Password
                  is required</p>
              </div>
              <div class="mar-t-40">
                <h2 class="margin-0"><label class="user-label">Can we help?</label></h2>
              </div>
              <mat-expansion-panel class="otp-expansion-panel mar-t-10" [expanded]="false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="login-info-title">This isn’t the right landline number</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-panel-description>
                  If these are not the last four digits of your landline number, please call us on<a href="tel:03450808500"
                    class="ib-link-row">0345 08 08 500</a> to update your contact details. </mat-panel-description>
              </mat-expansion-panel>
              <mat-expansion-panel class="otp-expansion-panel mar-t-10" [expanded]="false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="login-info-title">I haven’t received a call</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-panel-description>
                  If you have not received a call, please call us on<br><a href="tel:03450808500" class="ib-link-row">0345 08 08 500</a>
                  and we will help you. </mat-panel-description>
              </mat-expansion-panel>

              <mat-expansion-panel class="otp-expansion-panel mar-t-10" [expanded]="false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="login-info-title">Please text the code to my mobile</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-panel-description>
                  We will text your code to your mobile number ending {{landline}} <span><button mat-flat-button class="ib-link-btn button-link" type="button"  tabindex="0"(click)="onReqText()">Request a text</button></span>
                  Not your mobile number? Please call us on <a href="tel:03450808500" class="ib-link-row"  aria-label="requestCall">0345 08 08 500.</a>
                </mat-panel-description>
              </mat-expansion-panel>
            </div>

            <div class="mar-t-10">
              <div class="ib-ibid-button" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between center">
                <div ngClass.xs="width-100">
                  <div class="mar-t-10 mar-b-40" ngClass.xs="text-center">
                    <span aria-busy="true" ngClass.xs="text-center">
                      <img *ngIf="transmitLoader" class="btn-loader" alt="loading" [src]="env.loader">
                      <button mat-flat-button color="primary" class="continue ibid-btn" type="submit" (click)="onSubmitLandline()"
                        aria-label="Submit OTP" ngClass.xs="width-100">Confirm</button>
                    </span>
                  </div>
                </div>
                <div *ngIf="false">
                  <button class="btn" (click)="cancelLogin()" aria-label="Cancel log in click here"
                    class="ib-link-btn" target="_blank" title="Cancel log in">Default</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-card>
  </div>
</main>