import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store/store.service';
import { ActionTypes } from '../../../services/store/actions.service';
import { SessionService } from 'src/app/services/store/session.service';
import { DomService } from 'src/app/utils/dom.service';

@Component({
  selector: 'customer-type-selector',
  templateUrl: './customer-type-sel.component.html',
  styleUrls: ['./customer-type-sel.component.less']
})
export class CustomerTypeSelComponent implements OnInit{

  customerTypes: {type:string,selected:boolean,loginType:string}[] = [
    {type:'Personal',selected:false,loginType:'retail/personal-business'},
    {type:'Business Internet Banking',selected:false,loginType:'retail/personal-business-sme'},
    {type:'Business Online Plus',selected:false,loginType:'commercial/business-online-plus'},
    {type:'Commercial Online Banking',selected:false,loginType:'commercial/commercial-online-banking'}
  ];

  constructor(private store : StoreService ,private session : SessionService, private actions : ActionTypes, private dom : DomService) {}

  ngOnInit(){

    if(this.dom.isTimedoutEDGE()){
      this.store.dispatch(this.actions.SHOW_MODAL,{display:true,errortype:'TIMEOUT'});
    }
    const tpp = this.dom.getLocationParams();
    const tppObj = this.dom.getTppParams();
    //MFLOW Changes
    const isMFlow = this.dom.isMFlowSSOLogin();
    if(isMFlow){
      const mFlowQParamObj = this.dom.getMFlowQParams();
      this.session.mFlowQParamObj = mFlowQParamObj;
      this.session.isMFlow = true;
      this.session.tppParam = null;
      this.session.tppObj = {};
    }else{
      if(tpp){
        this.session.tppParam = tpp;
        this.session.tppObj = tppObj;
      }else{
        this.session.tppParam = null;
        this.session.tppObj = {};
      }
      this.session.mFlowQParamObj = null;
      this.session.isMFlow = false;
    }
    this.store.dispatch(this.actions.CUSTOMER_TYPE_CHANGE,'retail/personal-business');
  }
}
