import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store/store.service';
import { ActionTypes } from '../../../services/store/actions.service';
import { LoadcmsService } from 'src/app/services/loadcms.service';

@Component({
  selector: 'browser-bind',
  templateUrl: './browser-bind.component.html',
  styleUrls: ['./browser-bind.component.less']
})
export class BrowserBindComponent implements OnInit {
  showBrowserBindScreen  = false;
  descriptionTemplate: any;
  msgTemplate: any; 

  constructor(private store : StoreService, private action : ActionTypes, private cms : LoadcmsService) { 
    this.store.storeCB(this.storeFns());
  }

  ngOnInit() {
    /**
     * Empty ngOnInit
     */
  }
  storeFns(){
    return {
      showBrowserBind : this.showBrowserBind.bind(this)
    }
  }
  rememberBrowser(value : boolean){
    this.store.dispatch(this.action.BROWSER_BIND_SUBMIT,{bindDevice:value});
  }
 
  showBrowserBind(browserBind:any){
    this.showBrowserBindScreen = browserBind.show;
    if(browserBind.errorType){
      this.msgTemplate = this.cms.cms.content['err-modal'][browserBind.errorType];
      this.descriptionTemplate = this.cms.cms.content['err-modal'][browserBind.errorType+'_WHY'];
    }
  }
}
