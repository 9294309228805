import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

export interface FeatureToggles {
  otp: {
    landlineOtp: FeatureToggleStatus;
  }
}

export interface FeatureToggleStatus {
  activated: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  ENV = environment.environment;
  IS_PRODUCTION = environment.production;
  featureTogglesConfig: FeatureToggles;

  httpOptions = {
    headers : new HttpHeaders({})
  }

  

  constructor(private http : HttpClient ) { }

  post(url:string,body:any): Observable<any>{
   return this.http.post(url,body);
  }

  get(url:string,headers?:{key:string,value:any}[]): Observable<any>{
    if(headers){
      headers.forEach(header=>{
       this.httpOptions.headers = this.httpOptions.headers.set(header.key,header.value);
      })
    }

    return this.http.get(url,this.httpOptions);
  }

  public loadFeatureConfigs() {
    return new Promise((resolve, reject) => {
      const envExtension = (this.ENV === 'local') ? '' : `.${this.ENV}`;
      const filePath = `config/features/features${envExtension}.json`;

      this.http.get<FeatureToggles>(filePath)
        .pipe(
          map((configs) => this.featureTogglesConfig = configs)
        )
        .subscribe(resolve, reject);

    }).catch((e) => { 
      // Empty block to overcome app crash
    });
  }

  public getConfig() {
    return this.featureTogglesConfig || {};
  }

}
