<div
  class="custom-snackbar"
  [ngClass]="data.customClass" fxLayout="row" fxLayoutAlign="space-around center">
  <span
    fxHide.xs = true;
    class="icon">
    <mat-icon svgIcon="{{ data.icon }}">
    </mat-icon>
  </span>
    <span
      class="text-snackbar"
      [innerHTML]="data.text">
    </span>
    <span
      (click)="dismiss()">
      <mat-icon class="close-icon" svgIcon="{{ data.closeIcon }}">
      </mat-icon>
    </span>
</div>
