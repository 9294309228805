import {
  Directive,
  ElementRef,
  HostListener,
  Input
} from '@angular/core';

@Directive({
  selector: '[appKeepMoving]'
})
export class KeepMovingDirective {

  @Input() excludeId: string;

  constructor(private element: ElementRef) {}

  @HostListener('keyup', ['$event']) onkeyup(event: KeyboardEvent) {
    this.onkeyUpEvent(event);
  }

  onkeyUpEvent(event: KeyboardEvent) {
    let inputs = this.element.nativeElement.querySelectorAll('input');
    for (let j = 0; j < inputs.length; j++) {
      if (inputs[j] && !inputs[j].value) {
        if (inputs[j - 1] && inputs[j - 1].id !== this.excludeId) {
          inputs[j].focus();
        }
        break;
      }
    }

  }

}