<div class="toast-stroke-bar"></div>


<div *ngIf="options.progressBar && !options.disableTimeOut" class="toast-progress-bar-wrappers">
  <div class="toast-progress-bar" [style.width]="width + '%'">
  </div>
</div>

<div class="toast-wrapper" fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="16px">

  <!-- Icon -->
  <div fxFlex="24px" class="toast-icon" fxLayoutAlign="center center">
    <mat-icon fxFlex class="error">
    </mat-icon>

    <div fxFlex="24px" fxLayoutAlign="center center">
    <mat-icon fxFlex class="success" svgIcon="ib_check">
    </mat-icon>

  </div>
  </div>

  <div fxFlex class="toast-content">
    <!-- Title -->
    <div *ngIf="title" [class]="options.titleClass" class="toast-title" [attr.aria-label]="title">
      {{ title }}
    </div>

    <!-- Message - HTML -->
    <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite" class="toast-message toast-html-message"
      [class]="options.messageClass" [innerHTML]="message">
    </div>

    <!-- Message - Non-HTML -->
    <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite" class="toast-message"
      [class]="options.messageClass" [attr.aria-label]="message">
      {{ message }}
    </div>
  </div>

  <div *ngIf="options.closeButton" fxFlex="24px" fxLayoutAlign="center center" class="toast-close" (click)="remove()">
    <mat-icon fxFlex svgIcon="ib_close"></mat-icon>
  </div>
</div>