<div class="row">
    <div class="mar-t-20">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <div class="info-logo">
                <div class="mar-t-15">
                    <img class="logo-img" src="./assets/images/metrobank-logo.png" alt="Metro Bank Logo">
                    <div class="mar-t-15">
                        <h3 class="welcome-message">Welcome to Internet Banking</h3>
                    </div>
                </div>
            </div>
            <div class="steps">
                <div>Log in to your accounts</div>
                <div>
                    Step <span class="badge"> 
                            <span *ngIf="!pwdPage && !otpPage">1</span>
                            <span *ngIf="pwdPage || otpPage">2</span> 
                    </span> of 2
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <!--<div class="info-panel">
                <div>
                    <span class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="padding-left: 5px;padding-right:5px">
                        <span class="glyphicon glyphicon-info-sign info-icon"></span>
                    </span>
                    <span class="col-lg-10 col-md-10 col-sm-10 col-xs-10" style="padding-left: 5px;padding-right:5px">

                        <span class="fontWeight600 fontSize1p2em">
                            {{cmsTemplate.infoTitle}}</span>

                        <div class="info-msg">
                            <div [innerHTML]="cmsTemplate.infoBody"></div>
                        </div>

                    </span>
                </div>
            </div>-->
        </div>
    </div>
</div>