<header class="edge-header">
  <div class="header-banner">
    <div class="header-options">
      <div style="float: left;">
        <a href="https://www.metrobankonline.co.uk/" rel="noopener noreferrer" class="header-menu-link fontSizep9em"
          onclick="" target="_blank">Home</a>
      </div>
      <div id="COL_E69059C36009800B118607" class="dropdown header-hover-effect" style="float: left;">
            <div id="p1_GRP_E69059C36009800B117101" style="position: relative">
                <div style="clear: left;" id="row_BUT_E69059C36009800B115440" class="dropdown-toggle  ">
                  <div style="text-align: left; float: left;  " id="p4_BUT_E69059C36009800B115440">
                      <a href="javascript:void(0)" class="header-menu-link fontSizep9em">
                        <span appToolTip [elem]="'stores'" id="stores">Our Stores <strong class="caret"></strong>
                          <div style="display: none" class="dropdown-menu">
                            <div class="dropdown-arrow">
                            </div>
                            <div class="tool-tip-msg">
                              <strong>Our Stores are open 7 days a week, 362 days a year.</strong>
                            </div>
                            <div class="mar-t-10 tool-tip-msg">
                              We are only closed on New Year's Day, Easter Sunday and Christmas Day.
                            </div>
                            <hr style="margin-top:10px;margin-bottom:0px">
                            <div class="contact-store">
                              <span class="store-icon" data-icon="("></span>
                              <a href="https://www.metrobankonline.co.uk/get-in-touch/store-locator/" rel="noopener noreferrer" target="_blank" class="contact-store-a">Find your Local Store</a>
                      
                            </div>
                          </div>
                        </span>
                      </a>
                  </div>
                </div>
            </div>
      </div>
      <div id="COL_C6510857DFA48AE1302197" class="dropdown header-hover-effect" style="float: left;">
            <div id="p1_GRP_C6510857DFA48AE1301910" style="position: relative">
                <div style="clear: left;" id="row_BUT_C6510857DFA48AE1301911" class="dropdown-toggle  ">
                  <div style="text-align: left; float: left;  " id="p4_BUT_C6510857DFA48AE1301911">

                      <a href="javascript:void(0)" id="BUT_C6510857DFA48AE1301911"
                        class="header-menu-link drop-down-indicator fontSizep9em">
                        <span appToolTip [elem]="'contactus'" id="contactus">Contact us <strong class="caret"></strong>
                          <div style="display: none" class="dropdown-menu">
                            <div class="dropdown-arrow">
                            </div>
                            <div class="tool-tip-msg">
                              <strong class="contact-head">PERSONAL CUSTOMERS</strong>
                              <div class="mar-t-10">
                                <strong>On 0345 08 08 500</strong>
                              </div>
                            </div>
                            <hr style="margin-top:10px;margin-bottom:0px">
                            <div class="mar-t-10 tool-tip-msg">
                              <strong class="contact-head">BUSINESS CUSTOMERS</strong>
                              <div class="mar-t-10">
                                <strong>On 0345 08 08 508</strong>
                              </div>
                            </div>
                            <hr style="margin-top:10px;margin-bottom:0px">
                            <div class="mar-t-10 tool-tip-msg">
                              <strong class="contact-head">OUTSIDE THE UK?</strong>
                              <div class="mar-t-10">
                                <strong>Please call +44 20 3402 8312</strong>
                              </div>
                            </div>
                          </div>
                        </span>
                      </a>
                  </div>
                </div>

            </div>
      </div>
      <div id="COL_E69059C36009800B118616" class="floatRight" style="float: left!important;">
        <div>
          <div style="clear: left;" id="row_BUT_2F1A8E1F73CCF7BB35477">
            <div style="text-align: left; float: left;  " id="p4_BUT_2F1A8E1F73CCF7BB35477">
              <div>
                <a href="https://corporate.metrobankonline.co.uk/" rel="noopener noreferrer"
                  class="header-menu-link fontSizep9em" onclick="" target="_blank">Corporate Internet
                  Banking</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>
  </div>
</header>