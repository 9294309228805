<footer class="text-center sso-platform-selection ">
  <div class="choice-footer">
    <div class="layout-footer-separator">
      <div class="footer-separator"></div>
    </div>
    <div class="ib-bg-footer footer-content col-lg-12 col-md-12 col-sm-12 col-xs-12">Your eligible deposits with Metro Bank PLC are protected up to a total of £85,000 by the Financial Services Compensation Scheme, the UK's deposit guarantee scheme. Any deposits you hold above the limit are unlikely to be covered. For further information visit www.fscs.org.uk.
      <br>Metro Bank PLC. Registered in England and Wales. Company number: 6419578. Registered office: One Southampton Row, London, WC1B 5HA. We are authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and Prudential Regulation Authority. Metro Bank PLC is an independent UK Bank - it is not affiliated with any other bank or organisation (including the METRO newspaper or its publishers) anywhere in the world. "Metrobank" is the registered trademark of Metro Bank PLC.
      <br>
      <br>
      <p class="copy-right">
        Copyright 2020 Metro Bank. All rights reserved.
      </p>
      <br fxShow.xs = true; fxHide.md=false; fxHide.lg= false; >
      <a href="https://www.metrobankonline.co.uk/about-us/accessibility/" class="personal-service-content">Accessibility</a>
      <a href="https://www.metrobankonline.co.uk/about-us/legal-information/" class="personal-service-content">Legal Information</a>
      <a href="https://www.metrobankonline.co.uk/about-us/privacy-and-security/" class="personal-service-content">Privacy and Security</a>
      <a href="https://www.metrobankonline.co.uk/sitemap/" class="personal-service-content">SiteMap</a>
      <br fxShow.xs = true; fxHide.md=false; fxHide.lg= false; >
      <a target="blank"  href="https://www.fscs.org.uk">
        <img class="padding-top-9" src="assets/images/FSCS_logo.svg" alt="FSCS"/>
      </a>
    </div>
  </div>
</footer>

 