import {
  Component,
  ViewChild,
  ElementRef,
  ViewContainerRef,
  ComponentFactoryResolver,
  OnInit,
} from '@angular/core';
import { StoreService } from 'src/app/services/store/store.service';
import { SessionService } from 'src/app/services/store/session.service';
import { CookiesService } from 'src/app/services/cookies.service';
import { EnvService } from 'src/app/services/env.service';
import { ActionTypes } from '../../../services/store/actions.service';
import { DomService } from 'src/app/utils/dom.service';
import { NgForm } from '@angular/forms';
import { TransmitService } from 'src/app/transmit/transmit.service';
import { TimeoutService } from 'src/app/services/timeout.service';
import { LoadcmsService } from 'src/app/services/loadcms.service';
import { ConfigService } from 'src/app/services/rest/config.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorLabelLockComponent } from '../error-label-lock/error-label-lock.component';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.less'],
})
export class LoginFormComponent implements OnInit {
  @ViewChild('ibidinput') ibid: ElementRef;
  @ViewChild('sec0') sec0: ElementRef;
  @ViewChild('security0hidden') security0hidden: ElementRef;
  @ViewChild('pwdForm') pwdForm: NgForm;

  @ViewChild('transmitContainer') transmitContainer: ViewContainerRef;

  idProvided = false;
  username: string;
  saveID = false;
  pwdPage = false;
  otpPage = false;
  locked = false;
  securityOptions: string[] = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
  ];
  passwordPosValues: any;
  passwordValue = '';
  passwordPositions: { position: string; value: string }[];
  securityPositions: { position: string; value: string }[];
  transmitLoader = false;
  hideOnBind = false;

  /* CR86 */

  ibidInvalid = false;

  /* CR86 */

  ngOnInit() {
    this.transmitService.initialize();
    this.initLogin();
  }

  storeFns() {
    return {
      loginrefresh: this.initLogin.bind(this),
      pwdseeds: this.pwdSeeds.bind(this),
      hideOnOtp: this.showOTP.bind(this),
      hideOnBrowserBind: this.hideOnBrowserBind.bind(this),
    };
  }

  showIBID() {
    this.gaEntry('Login');
    this.pwdPage = false;
    this.otpPage = false;
    this.store.dispatch(this.actions.STEPUPDATE, {
      otp: this.otpPage,
      pwd: this.pwdPage,
    });
  }
  showPWD() {
    this.pwdPage = true;
    this.otpPage = false;
    this.store.dispatch(this.actions.STEPUPDATE, {
      otp: this.otpPage,
      pwd: this.pwdPage,
    });
  }
  showOTP() {
    this.pwdPage = false;
    this.otpPage = true;
    this.store.dispatch(this.actions.STEPUPDATE, {
      otp: this.otpPage,
      pwd: this.pwdPage,
    });
  }

  hideOnBrowserBind() {
    this.hideOnBind = true;
  }

  gaEntry(page: string) {
    if (window['ga']) {
      window['ga']('send', 'pageview', page);
    }
  }

  numToWords(num: string) {
    let words = this.content.cms.content['num-words'];
    return words[num];
  }

  transmitLogout() {
    this.transmitService
      .logout()
      .then((result) => {
        /**
         * empty function
         */
      })
      .catch((error) => {
        /**
         * empty function
         */
      });
  }

  constructor(
    private resolver: ComponentFactoryResolver,
    private store: StoreService,
    private session: SessionService,
    private cookies: CookiesService,
    public env: EnvService,
    private actions: ActionTypes,
    private dom: DomService,
    private transmitService: TransmitService,
    private timeout: TimeoutService,
    private content: LoadcmsService,
    private rest: ConfigService,
    public dialog: MatDialog,
    public googleAnalytics: GoogleAnalyticsService
  ) {
    this.store.storeCB(this.storeFns());
  }

  getUIContext() {
    return {
      viewContainerRef: this.transmitContainer,
      resolver: this.resolver,
    };
  }

  initLogin() {
    this.passwordValue = '';
    this.passwordPositions = [
      { position: '-', value: null },
      { position: '-', value: null },
      { position: '-', value: null },
    ];
    this.securityPositions = [
      { position: '-', value: null },
      { position: '-', value: null },
      { position: '-', value: null },
    ];
    this.showIBID();
    this.transmitLoader = false;
    this.locked = false;
    const userinCookie = this.cookies.get('sso_mb_userid');
    setTimeout(() => {
      if (userinCookie) {
        this.username = userinCookie;
        this.saveID = true;
      }
      this.ibid.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      this.transmitService.uiContext = this.getUIContext();
    });
  }

  pwdSeeds(pwds) {
    this.passwordValue = '';
    this.showPWD();
    this.transmitLoader = false;
    const labels = ['', 'st', 'nd', 'rd', 'th'];
    const pin = pwds.seeds;
    this.securityPositions = [
      { position: `${pin[0]}${labels[pin[0] - 0] || labels[4]}`, value: '' },
      { position: `${pin[1]}${labels[pin[1] - 0] || labels[4]}`, value: '' },
      { position: `${pin[2]}${labels[pin[2] - 0] || labels[4]}`, value: '' },
    ];
    const pwd = pwds.pwd;
    this.passwordPosValues = pwd;
    this.passwordPositions = [
      { position: `${pwd[0]}${labels[pwd[0] - 0] || labels[4]}`, value: '' },
      { position: `${pwd[1]}${labels[pwd[1] - 0] || labels[4]}`, value: '' },
      { position: `${pwd[2]}${labels[pwd[2] - 0] || labels[4]}`, value: '' },
    ];
  }

  redirectToPhoenix(value: string) {
    this.ibidCookie();
    this.rest
      .post(this.content.cms.connections.phoenix.verify, { jwtToken: value })
      .subscribe((response) => {
        this.dom.redirectToURL(this.content.cms.connections.phoenix.url);
      });
  }

  redirectToBeta(value: string) {
    this.ibidCookie();
    this.rest
      .post(this.content.cms.connections.beta.verify, { jwtToken: value })
      .subscribe((response) => {
        this.dom.redirectToURL(this.content.cms.connections.beta.url);
      });
  }

  redirectToEdge(value: string, nonsca: boolean) {
    this.ibidCookie();
    this.transmitLoader = false;
    let edgeUrl = `${this.content.cms.connections.edge.url}/MetroBankRetail/servletcontroller`;
    if (this.session.tppParam) {
      edgeUrl += this.session.tppParam + '&';
    } else {
      edgeUrl += '?';
    }
    if (nonsca) {
      edgeUrl += `ib_id=${btoa(value)}`;
    } else {
      edgeUrl += `sso_token=${value}`;
    }
    this.dom.redirectToURL(edgeUrl);
  }

  submitID() {
    this.googleAnalytics.sendButtonClickEvent(
      {
        attributes: {
          eventName: 'buttonClick',
          eventAction: 'login',
          eventDetail: 'enter customer number or username - continue',
        },
      },
      'login',
      'sso'
    );
    if (this.username) {
      this.timeout.throttleTimeout();
      const isIbId = /^\d{12}$/.test(this.username);
      this.transmitLoader = true;
      this.ibidInvalid = false;
      this.transmitService
        .authenticate(this.username, isIbId)
        .then(this.handleSuccess.bind(this))
        .catch((error) => {
          if (
            error.getErrorCode() !==
            com.ts.mobile.sdk.AuthenticationErrorCode.UserCanceled
          ) {
            this.handleLoginError(error);
          }
        });
    }
  }

  showPwdPage() {
    if (this.otpPage) {
      this.showPWD();
    } else {
      this.pwdForm.resetForm();
    }
    this.submitID();
  }

  //MFLOW Changes
  reDirectToMFlowConsentURL(result: any) {
    if (result && result['_token']) {
      let queryString = '/?';
      for (let query in this.session.mFlowQParamObj) {
        //removing jwt tpp token received from soruce req
        if (query !== 'request' && query !== 'tpp_jwt') {
          queryString += `${query}=${this.session.mFlowQParamObj[query]}&`;
        }
      }
      const jwtParam = 'jwt=' + result['_token'];
      const consentURL =
        this.content.cms.connections.mflow.consentURL + queryString + jwtParam;
      this.dom.redirectToURL(consentURL);
    }
  }

  handleSuccess(authenticationResult: com.ts.mobile.sdk.AuthenticationResult) {
    const result = authenticationResult;
    // IB rollout flow
    if (result['_internalData']['json_data']['platform_to_redirect']) {
      switch (result['_internalData']['json_data']['platform_to_redirect']) {
        case 'phoenix': {
          this.newOnlineFlow(result, true);
          break;
        }
        case 'beta': {
          this.newOnlineFlow(result, false);
          break;
        }
        case 'edge': {
          if (result && result['_internalData']['json_data']['sso_ticket']) {
            this.transmitLogout();
            this.redirectToEdge(
              result['_internalData']['json_data']['sso_ticket'],
              false
            );
          }
          break;
        }
        case 'mflow': {
          //MFlow changes
          this.reDirectToMFlowConsentURL(result);
          break;
        }
        default: {
          this.displayError('general');
          break;
        }
      }
    }
    // Legacy Flow
    else {
      this.legacyFlow(result);
    }
  }

  legacyFlow(result) {
    if (result && result['_internalData']['json_data']['sso_ticket']) {
      this.transmitLogout();
      this.redirectToEdge(
        result['_internalData']['json_data']['sso_ticket'],
        false
      );
    } else {
      this.displayError('general');
    }
  }
  newOnlineFlow(result, isPhoenix) {
    if (result && result['_token']) {
      if (isPhoenix) {
        this.redirectToPhoenix(result['_token']);
      } else {
        this.redirectToBeta(result['_token']);
      }
    }
  }
  /* eslint-disable */
  handleLoginError(error: com.ts.mobile.sdk.AuthenticationError) {
    this.transmitLoader = false;
    let errorMessage = 'general';
    let errorData = error.getData()['data'];
    if (
      errorData &&
      errorData.failure_data &&
      errorData.failure_data.reason &&
      errorData.failure_data.reason.data
    ) {
      /* CR86 */

      if (errorData.failure_data.reason.data.invalid_ibid) {
        errorMessage = 'invalidibid';
      }

      /* CR86 */
      if (errorData.failure_data.reason.data.locked) {
        errorMessage = 'locked';
      }
      if (errorData.failure_data.reason.data.login_unsuccessful) {
        if (errorData.failure_data.reason.data.login_fail_count) {
          errorMessage = `unsuccessful${errorData.failure_data.reason.data.login_fail_count}`;
        } else {
          errorMessage = 'unsuccessful';
        }
      }
      if (errorData.failure_data.reason.data.non_sca) {
        errorMessage = 'redirectedge';
      }
    }
    if (errorMessage === 'general') {
      const respErr = error.getMessage();
      if (respErr && respErr.indexOf('Device not found') >= 0) {
        this.transmitService.unbindUserFromSDK(this.username);
        this.submitID();
      } else {
        this.displayError(errorMessage);
      }
    } else if (errorMessage === 'redirectedge') {
      this.redirectToEdge(errorData.failure_data.reason.data.ib_id, true);
    } else {
      if (errorMessage !== 'locked') {
        if (errorMessage === 'invalidibid') {
          this.ibidInvalid = true;
        } else {
          this.displayError(errorMessage);
        }
      } else {
        this.store.dispatch(this.actions.HIDE_LOGIN_ERROR, true);
        this.store.dispatch(this.actions.SHOW_OTP, {
          show: false,
          mobile: null,
          fake: false,
        });
        this.showPWD();
        this.locked = true;

        const dialogRef = this.dialog.open(ErrorLabelLockComponent, {
          backdropClass: 'backdropBackground',
          disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
          /**
           * Empty arrow function
           */
        });
      }
    }
  }
  /* eslint-enable */

  displayError(errorMessage: string) {
    if (errorMessage.indexOf('unsuccessful') >= 0) {
      this.showPwdPage();
    } else {
      this.store.dispatch(this.actions.REFRESH_LOGIN);
    }
    this.store.dispatch(this.actions.SHOW_OTP, {
      show: false,
      mobile: null,
      fake: false,
    });
    this.store.dispatch(this.actions.SHOW_LOGIN_ERROR, errorMessage);
  }

  ibidCookie() {
    if (this.saveID) {
      this.cookies.set('sso_mb_userid', this.username);
    } else {
      this.cookies.remove('sso_mb_userid');
    }
  }

  getSeededValuesFromPassword() {
    this.passwordPosValues.forEach((val, key) => {
      this.passwordPositions[key].value = this.passwordValue[val - 1];
    });
  }

  submitLoginForm() {
    let data;
    if (
      this.passwordValue &&
      this.securityPositions[0].value &&
      this.securityPositions[1].value &&
      this.securityPositions[2].value
    ) {
      data = {
        password: this.passwordValue,
        pinSeeds: [
          this.securityPositions[0].value,
          this.securityPositions[1].value,
          this.securityPositions[2].value,
        ],
      };
    }
    if (data) {
      this.transmitLoader = true;
      this.store.dispatch(this.actions.SUBMITPWD, data);
      this.timeout.throttleTimeout();
    }
  }

  customernumberGa() {
    this.googleAnalytics.sendButtonClickEvent(
      {
        attributes: {
          eventName: 'buttonClick',
          eventAction: 'login',
          eventDetail: 'forgotten your customer number or username?',
        },
      },
      'login',
      'sso'
    );
  }

  securitynumberGa() {
    this.googleAnalytics.sendButtonClickEvent(
      {
        attributes: {
          eventName: 'buttonClick',
          eventAction: 'login',
          eventDetail: 'forgotten your password or security number?',
        },
      },
      'login',
      'sso'
    );
  }

  onlinebankingGa() {
    this.googleAnalytics.sendButtonClickEvent(
      {
        attributes: {
          eventName: 'buttonClick',
          eventAction: 'login',
          eventDetail: 'new to online banking? register here',
        },
      },
      'login',
      'sso'
    );
  }

  cancelLogin() {
    this.transmitService.forceCancelTransmitJourney();
    this.initLogin();
  }
}
