<nav class="navbar navbar-default ">
  <div ngClass="header-container-lg" ngClass.md="header-container-md">
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <img class="logo-img logo" ngClass="logo-lg" src="./assets/images/metrobank-logo.png" alt="Metro Bank Logo">
    </div>
     <!-- Collect the nav links, forms, and other content for toggling -->
    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1" >
      <div class="menu-container-lg" fxLayout ="column" fxLayoutAlign ="center center" >
      <ul class="nav navbar-nav" ngClass ="menu-lg" >
        <li class="nav-item" >
          <a tabindex="-1" href="https://www.metrobankonline.co.uk/ways-to-bank/products/personal-internet-banking/" class="home-menu">Home</a>
        </li>
        <li class="nav-item">
          <a tabindex="-1" href="https://www.metrobankonline.co.uk/ways-to-bank/i-want-some-information-about/fraud-and-security/#mbc-c33fe513-2fff-46c9-adbd-5a1134a9bb70" class="stay-safe-online">Stay Safe Online</a>
        </li>
        <li class="nav-item">
          <a tabindex="-1" href="https://www.metrobankonline.co.uk/personalservicequality/" class="service-quality-metrics">Service Quality Metrics</a>
        </li>
      </ul>
      </div>


    </div><!-- /.navbar-collapse -->
  </div><!-- /.container-fluid -->
</nav>
