import { Component } from '@angular/core';
import { StoreService } from 'src/app/services/store/store.service';
import { ActionTypes } from 'src/app/services/store/actions.service';
import { LoadcmsService } from 'src/app/services/loadcms.service';
import { DomService } from 'src/app/utils/dom.service';

@Component({
  selector: 'err-modal',
  templateUrl: './err-modal.component.html',
  styleUrls: ['./err-modal.component.less']
})
export class ErrModalComponent  {

  msgTemplate  = '';
  errorHeading = '';
  displayModal  = false;
  titleText : string;
  descriptionTemplate : string;
  errorType : string;
  redirect : string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  cb : Function;

  constructor(
    private store : StoreService,
    private action : ActionTypes,
    private template : LoadcmsService,
    private dom : DomService
    ) {
    store.storeCB({
      displaymodal: this.displayModalFn.bind(this)
    })
   }

   extractContent(htmlString) {
    let span = document.createElement('span');
    span.innerHTML = htmlString;
    return (span.textContent || span.innerText).split('.')[0];
  }

   displayModalFn(modal){
    this.displayModal = modal.display;
    this.titleText = modal.title;
    this.errorType = modal.errortype;
    this.redirect = modal.redirect;
    this.cb = modal.cb;
    if(this.errorType){
      this.msgTemplate = this.template.cms.content['err-modal'][this.errorType];
      this.errorHeading = this.extractContent(this.template.cms.content['err-modal'][this.errorType]);
      this.descriptionTemplate = this.template.cms.content['err-modal'][this.errorType+'_WHY'];
    }
  }

   rememberBrowser(value : boolean){
    this.displayModal = false;
    this.cb(value);
   }

   reLogin(){
    this.displayModal = false;
    this.store.dispatch(this.action.REFRESH_LOGIN);
    this.dom.redirectToSso();
   }

}
