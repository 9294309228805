import { Injectable } from '@angular/core';

@Injectable()
export class Debouncer {
    constructor(){
        /** empty constructor for debouncer */
    }

    timeout : any;

    debounce(fn:any,debounceTimer:number){
        clearTimeout(this.timeout);
        this.timeout = setTimeout(()=>{
            fn();
        },debounceTimer);
    }
}