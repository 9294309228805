import { Component, OnInit } from '@angular/core';
import { EnvService } from 'src/app/services/env.service';
import { LoadcmsService } from 'src/app/services/loadcms.service';
import { StoreService } from 'src/app/services/store/store.service';

@Component({
  selector: 'edge-band',
  templateUrl: './edge-band.component.html',
  styleUrls: ['./edge-band.component.less']
})
export class EdgeBandComponent implements OnInit{
  cmsTemplate : any;
  pwdPage  = false;
  otpPage  = false;

  constructor(public env : EnvService,private template: LoadcmsService, private store : StoreService) {
    this.store.storeCB({
      stepupdate: (show)=>{
        this.otpPage = show.otp;
        this.pwdPage = show.pwd;
      }
    })
   }

  ngOnInit(){
    this.cmsTemplate = this.template.cms.content['edge-band'];
  }

}
