import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconProviderService {
  icons = [
    // 16
    ['home', '16/Home.svg'],
    ['metro', '16/Metro.svg'],
    ['warning', '16/Warning.svg'],
    ['logout', '16/Logout.svg'],
    ['info-16', '16/Info.svg'],
    ['question-16', '16/Question.svg'],
    ['swap', '16/ChevronR.svg'],

    // 20
    ['new_tab', '22/NewTab.svg'],
    ['cookie_new_tab', '22/CookieNewTab.svg'],
    ['cookie_alert','22/CookieAlert.svg'],
    // 22
    ['plus', '22/Plus.svg'],
    ['hint', '22/Hint.svg'],
    ['hint_full', '22/HintFull.svg'],
    ['phone', '22/Phone.svg'],
    ['alert', '22/Alert.svg'],
    ['alert_err', '22/AlertErr.svg'],
    ['bin', '22/Bin.svg'],
    ['chevron_up', '22/ChevronU.svg'],
    ['chevron_down', '22/ChevronD.svg'],
    ['chevron_left', '22/ChevronL.svg'],
    ['chevron_right', '22/ChevronR.svg'],
    ['info-22', '22/Info.svg'],
    ['info-alert-22', '22/Info-Alert.svg'],
    ['error', '22/Error.svg'],
    ['save', '22/Save.svg'],
    ['search-22', '22/Search.svg'],
    ['download', '22/Download.svg'],

    ['accounts', '22/Home.svg'],
    ['beneficiaries', '22/User.svg'],
    ['cloud', '22/Cloud.svg'],
    ['settings', '22/Cog.svg'],
    ['help', '22/Question.svg'],
    ['logout', '22/Exit.svg'],
    ['building', '22/Building.svg'],
    ['strands-invoices', '22/Strands-invoices.svg'],
    ['strands-bills', '22/Strands-bills.svg'],

    // 24
    ['check', '24/Check.svg'],
    ['user', '24/User.svg'],
    ['search', '24/Search.svg'],
    ['pound', '24/Pound.svg'],
    ['info', '24/Info.svg'],
    ['info_outline', '24/Info-Outline.svg'],
    ['calendar', '22/Calendar.svg'],
    ['transfer', '24/Transfer.svg'],
    ['card', '24/Card.svg'],
    // ['settings', '24/Settings.svg'],
    ['options', '24/Options.svg'],
    ['key', '24/Key.svg'],
    ['close', '24/X.svg'],
    ['edit', '24/Edit.svg'],
    ['logo', '24/Logo.svg'],
    ['clock', '24/Clock.svg'],
    ['question', '24/Question.svg'],
    ['signout', '24/SignOut.svg'],
    ['backspace', '24/Backspace.svg'],

    // 32
    ['left', '32/Left.svg'],
    ['right', '32/Right.svg'],
    ['question', '24/Question.svg'],
    ['up', '32/ChevronD-1.svg'],
    ['down', '32/ChevronD-2.svg'],
    ['burger', '32/Burger.svg'],
    ['business', '32/Business.svg'],
    ['personal', '32/Personal.svg'],
    ['introducer', '32/Introducer.svg'],

    // 48
    ['x', '48/X.svg'],
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
  }

  init() {
    this.icons.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        `ib_${icon[0]}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon[1]}`)
      );
    });
  }
}
