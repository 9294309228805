<div class="footer">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div style="text-align: center;">
                <div class="mar-t-40">
                    <h2>Any Questions? Please call us on <a href="tel: 0345 08 08 500">0345 08 08 500</a> if you are a
                        Personal Customer, </h2>
                    <br>
                    <h2>or if you are a Business Customer on <a href="tel: 0345 08 08 508">0345 08 08 508</a>. We are
                        here to help.</h2>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-info-box">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="mar-t-40">
                    <div class="feature-cover">
                        <div class="feature-portion">
                            <div class="feature">
                                <header class="feature-header">
                                    <div class="header-content">New Customers</div>
                                </header>
                                <div>
                                    <div class="body-content">If you have not already opened an account with us, please
                                        <a rel="noopener noreferrer"
                                            href="https://www.metrobankonline.co.uk/bank-accounts/i-want-some-information-about/what-i-need-to-open-a-personal-account/"
                                            target="_blank">click here</a> for information on what you need to open
                                        an account.</div>
                                </div>
                            </div>
                        </div>
                        <div class="feature-portion">
                            <div class="feature">
                                <header class="feature-header">
                                    <div class="header-content">Useful help and support</div>
                                </header>
                                <div>
                                    <div class="body-content">Find <a
                                            href="https://www.metrobankonline.co.uk/ways-to-bank/"
                                            rel="noopener noreferrer" target="_blank">useful help and support </a>on how
                                        to
                                        manage your accounts as well
                                        as more information about the Internet Banking service.</div>
                                </div>
                            </div>
                        </div>
                        <div class="feature-portion">
                            <div class="feature">
                                <header class="feature-header">
                                    <div class="header-content">We take security very seriously</div>
                                </header>
                                <div>
                                    <div class="body-content">
                                        <p>
                                                We continuously strive to make banking online safer. To find out
                                                more about security <a
                                                    href="https://www.metrobankonline.co.uk/ways-to-bank/i-want-some-information-about/banking-securely/"
                                                    rel="noopener noreferrer" target="_blank">click here. </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="feature-portion">
                            <div class="feature">
                                <header class="feature-header">
                                    <div class="header-content">Service Quality Metrics</div>
                                </header>
                                <div>
                                    <div class="body-content">
                                        <p>
                                                Find out how Metro Bank compares to other banks in an independent
                                                service quality survey
                                                <a href="https://www.metrobankonline.co.uk/personalservicequality"
                                                    rel="noopener noreferrer" target="_blank">click here. </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="mar-t-40">
        <div class="row">
            <p class="footer-content">Your eligible deposits with Metro Bank PLC are protected up to a total of £85,000
                by the Financial Services
                Compensation Scheme, the UK's deposit guarantee scheme. Any deposits you hold above the limit are
                unlikely
                to be covered. For further information visit <a href="http://www.fscs.org.uk/" rel="noopener noreferrer"
                    target="_blank"><b>www.fscs.org.uk.</b></a>.
            </p>
        </div>
    </div>

    <div class="mar-t-20">
        <div class="row">
                <p class="footer-content">Metro Bank PLC. Registered in England and Wales. Company number: 6419578.
                        Registered office: One Southampton
                        Row, London, WC1B 5HA. We are authorised by the Prudential Regulation Authority and regulated by the
                        Financial Conduct Authority and Prudential Regulation Authority. Metro Bank PLC is an independent UK
                        Bank -
                        it is not affiliated with any other bank or organisation (including the METRO newspaper or its
                        publishers)
                        anywhere in the world. "Metrobank" is the registered trademark of Metro Bank PLC.</p>
        </div>
    </div>

    <div class="mar-t-20">
        <div class="row">
            <div class="copyright">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <em class="no-print">
                                    © Metro Bank {{date}}
                                </em>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <a style="float:right" href="https://www.fscs.org.uk/" target="_blank" rel="noopener noreferrer">
                                <img class="info-icon" [src]="env.fscs" alt="FSCS Leader Banner">
                            </a>
                    </div>
            </div>
        </div>
    </div>
</div>