import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ib-header',
  templateUrl: './ib-header.component.html',
  styleUrls: ['./ib-header.component.less']
})
export class IbHeaderComponent implements OnInit {

  constructor() {
    /**
     * Empty constructor
     */
   }

  ngOnInit() {
    /***
     * empty ngOnInit
     */
  }

}
