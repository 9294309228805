import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    @Inject(MAT_SNACK_BAR_DEFAULT_OPTIONS) public config:any
  ) { }

  ngOnInit() {
    /**
     * empty ngOnInit
     */
  }

  dismiss() {
    if (this.data.action === 'close') {
      this.data.close();
    }
  }

}