import { Component, ViewChild, ElementRef } from '@angular/core';
import { StoreService } from 'src/app/services/store/store.service';
import { ActionTypes } from '../../../services/store/actions.service';
import { EnvService } from 'src/app/services/env.service';
import { TimeoutService } from 'src/app/services/timeout.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component'
import { ToastService } from 'src/app/services/toaster.service';
import { FeatureTogglesService } from 'src/app/services/feature-toggles.service';

@Component({
  selector: 'otp-hardtoken',
  templateUrl: './otp-hardtoken.component.html',
  styleUrls: ['./otp-hardtoken.component.less']
})
export class OtpHardtokenComponent {
  @ViewChild('inputElement') otp : ElementRef;
  
  showOtp  = false;
  customerType  = '';
  confirmDisabled  = true;
  transmitLoader  = false;
  mobile  = '';
  counter  = 0;
  fakeForm  = false;
  exceeded  = false;
  hideOnBind = false;
  otpPositions1 : {position:string,value:string}[] = [{position:'1st',value:null},{position:'2nd',value:null},{position:'3rd',value:null},{position:'4th',value:null}];
  otpPositions2 : {position:string,value:string}[] = [{position:'5th',value:null},{position:'6th',value:null},{position:'7th',value:null},{position:'8th',value:null}];
  otpValue = '';
  landline = '';
  isLandline = false;
  isMobileOtp = true;
  isLandlineFeature: boolean;

  storeFns(){
    return {
      submitotp : this.submitOtp.bind(this),
      showotp : this.showOTP.bind(this),
      hideOtpOnBrowserBind: this.hideOtpOnBrowserBind.bind(this)
    }
  }
  
  constructor(
    private store: StoreService,
    public env: EnvService,
    private actions: ActionTypes,
    private timer: TimeoutService,
    private snackBar: MatSnackBar,
    private toaster: ToastService,
    private ftService: FeatureTogglesService
  ) {
    this.store.storeCB(this.storeFns());
    this.isLandlineFeature = this.ftService.isOn('otp.landlineOtp');
  }

  openSnackBar(text: string, icon: string ,customClass: string , closeIcon: string,
    duration: number ,  action: string)
    {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration,
      verticalPosition: 'top',
      horizontalPosition:'right',
      panelClass: ['custom-snackbar'],
      data: {
        text,
        icon,
        customClass,
        closeIcon,
        action,
        close: () => {
          this.snackBar.dismiss();
        }
      }
    });
  }


  hideOtpOnBrowserBind(){
    this.hideOnBind = true;
  }
  cancelLogin(){
    this.otpValue = '';
    this.showOtp = false;
    this.store.dispatch(this.actions.REFRESH_LOGIN);
  }

  showOTP(otp:any){
    this.fakeForm = otp.fake;
    this.showOtp = otp.showOtp;
    this.mobile = otp.mobile;
    this.landline = otp.landline;
    this.otpValue = '';
    this.exceeded = otp.exceeded;
    if(otp.exceeded){
      this.store.dispatch(this.actions.SHOW_LOGIN_ERROR,'exceed');
    }
    this.otpPositions1 = [{position:'1st',value:null},{position:'2nd',value:null},{position:'3rd',value:null},{position:'4th',value:null}];
    this.otpPositions2 = [{position:'5th',value:null},{position:'6th',value:null},{position:'7th',value:null},{position:'8th',value:null}];
    if(otp.customerType){
      this.customerType = otp.customerType.split('/')[0];
    }
    if(otp.showOtp){
      this.transmitLoader = false;
      setTimeout(()=>{
        if(this.otp && this.otp.nativeElement){
            this.otp.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      });
    }
  }

  resendOtp(){
    this.store.dispatch(this.actions.HIDE_LOGIN_ERROR);
    const toasterTitle = 'We have re-sent the code';
    const toasterMessage = `We have resent code to your mobile ending with ${this.mobile}`;
    this.actionsToDispatch(2,toasterTitle, toasterMessage);
  }

  callAgain(value){
    this.otpValue = value;
    this.store.dispatch(this.actions.HIDE_LOGIN_ERROR);
    const toasterTitle = 'Calling you again.';
    const toasterMessage = `We have initiated another call to your landline ending in ${this.landline}`;
    this.actionsToDispatch(2,toasterTitle, toasterMessage);
  }

  submitOtp() {
    let token = '';
    token = this.otpValue;
    if (token && token.length === 8) {
      this.transmitLoader = true;
      this.timer.throttleTimeout();
      this.store.dispatch(this.actions.SUBMITTOKEN,{token,resend:false,counter:this.counter});
    }
  }

  submitLandlineOtp(value) {
    this.otpValue = value;
    this.submitOtp();
  }

  requestLandlineOtp() {
    const toasterTitle = 'Calling you';
    const toasterMessage = `We have initiated call to your landline ending in ${this.landline}`;
    this.actionsToDispatch(2, toasterTitle, toasterMessage);
    if (this.counter < 3) {
      this.isMobileOtp = false;
      this.isLandline = true;
    }
  }

  reqMobileOtp() {
    this.transmitLoader = false;
    if (this.counter < 3) {
      this.isLandline = false;
      this.isMobileOtp = true;
      this.resendOtp();
    }
  }

  actionsToDispatch(counter,toasterTitle, toasterMessage) {
    if (this.counter < counter) {
      this.transmitLoader = true;
      this.counter = this.counter + 1;
      this.timer.throttleTimeout();
      this.toaster.showSuccess( toasterMessage,toasterTitle);
       if (this.fakeForm) {
        this.store.dispatch(this.actions.SHOW_OTP, { show: true, mobile: this.mobile, fake: true,landline:this.landline });
      } else {
        this.store.dispatch(this.actions.SUBMITTOKEN, { token: null, resend: true, counter: this.counter });
      }
    } else {
      this.counter = this.counter + 1;
      this.store.dispatch(this.actions.SHOW_LOGIN_ERROR, 'exceed');
    }
  }

}

