import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TransmitmockService {
  transmitSDK: com.ts.mobile.sdk.TransmitSDKXm;
  uiHandler: com.ts.mobile.sdk.UIHandler;
  resolvelater: any;
  rejectlater: any;
  configValues: any;
  unsuccessfulCounter = 0;
  authRes = {};

  constructor() {}

  submitOtpHardToken(data) {}

  submitPwdSeeds(data) {}

  public initialize() {}

  public authenticate(username: string, isIbId: boolean) {}

  public unbindUserFromSDK(ibid: string) {}

  public logout() {}

  public forceCancelTransmitJourney() {}
}
