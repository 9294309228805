/* eslint-disable @typescript-eslint/naming-convention */
import { ActionTypes } from '../services/store/actions.service';
import { StoreService } from '../services/store/store.service';

let storeAction = null;
let action = null;
function CustomUIHandler(store: StoreService, actions: ActionTypes) {
  store.storeCB({
    submitpwdseeds: submitPwdSeeds.bind(this),
    submitotphardtoken: submitOtpHardToken.bind(this),
    submitchoiceselection: submitChoiceSelection.bind(this),
    submitBrowserBind: submitBrowserBind.bind(this),
  });
  storeAction = store;
  action = actions;
}

let resolvelater;
let pin_seeds;

function submitPwdSeeds(data) {
  resolvelater(
    com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest({
      password: data.password,
      pin_seeded_value: data.pinSeeds.join(''),
      pin_seeded_key: [pin_seeds[0] - 0, pin_seeds[1] - 0, pin_seeds[2] - 0],
    })
  );
}

function submitOtpHardToken(data) {
  resolvelater(
    com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest({
      otp_value: data.token,
      resend: data.resend,
      counter: data.counter,
    })
  );
}
function submitChoiceSelection(data) {
  resolvelater(
    com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest({
      choice: data.choice,
    })
  );
}

function submitBrowserBind(data) {
  data.formId = 'user-bind-confirmation-form';
  gtag(data.formId + ' : ' + data.bindDevice);
  resolvelater(
    com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest({
      bind_device: data.bindDevice,
    })
  );
}

function getRandomSeeds() {
  let seeds = [];
  for (let i = 0; i < 3; i++) {
    let seed = Math.ceil(Math.random() * Math.floor(8));
    if (seeds.indexOf(seed) < 0) {
      seeds.push(seed);
    } else {
      i -= 1;
    }
  }
  return seeds.sort((a, b) => a - b);
}

function gtag(form) {
  if (window['ga']) {
    window['ga']('send', 'pageview', form);
  }
}

CustomUIHandler.prototype.constructor = CustomUIHandler;

CustomUIHandler.prototype.processJsonData = function (
  jsonData,
  actionContext,
  clientContext
) {
  if (jsonData) {
    return new Promise(function (resolve, reject) {
      resolve({
        getContinueProcessing() {
          if (jsonData) {
            return true;
          } else {
            return false;
          }
        },
        setContinueProcessing(continueProcessing: boolean) {
          console.log(continueProcessing);
        },
      });
    });
  }
};

CustomUIHandler.prototype.createFormSession = function (formId, payload) {
  return {
    /* 
        form:formId,
        payload:payload, */
    startSession(clientContext, actionContext) {
      this.clientContext = clientContext;
      this.actionContext = actionContext;
    },
    promiseFormInput() {
      gtag(formId);
      if (formId === 'seed_positions_form') {
        if (payload.pass_seeded_positions.length === 0) {
          payload.show_fake_seeds = true;
        } else {
          payload.show_fake_seeds = false;
        }
        return this.renderPasswordSeedForm();
      } else if (formId === 'fake_hard_token_form') {
        return this.renderHardTokenForm();
      } else if (formId === 'hard_token_form') {
        return this.renderHardTokenForm();
      } else if (formId === 'otp_validate_form') {
        return this.renderHardTokenForm();
      } else if (formId === 'SSO_fake_otp_form') {
        return this.renderHardTokenForm();
      } else if (formId === 'user-bind-confirmation-form') {
        return this.renderHardTokenForm();
      } else if (formId === 'seeds_fake_positions_form') {
        payload.show_fake_seeds = true;
        return this.renderPasswordSeedForm();
      } else if (formId === 'sso_choice_screen') {
        return this.renderChoiceScreenForm();
      } else {
        return this.renderForm();
      }
    },
    renderHardTokenForm() {
      switch (formId) {
        case 'user-bind-confirmation-form':
          storeAction.dispatch(action.BROWSER_BIND, {
            show: true,
            description: payload.message,
            errorType: payload.warning
              ? 'BROWSER_BINDING_WARN'
              : 'BROWSER_BINDING',
          });
          break;
        case 'SSO_fake_otp_form':
          storeAction.dispatch(action.SHOW_OTP, {
            show: true,
            mobile: payload.mobile_hidden,
            fake: true,
            exceeded: payload.exceeded,
            landline: payload.landline_number,
          });
          break;
        default:
          storeAction.dispatch(action.SHOW_OTP, {
            show: true,
            mobile: payload.mobile_hidden,
            fake: false,
            exceeded: payload.exceeded,
            landline: payload.landline_number,
          });
          break;
      }
      return new Promise(function (resolve, reject) {
        resolvelater = resolve;
      });
    },
    renderPasswordSeedForm() {
      if (payload.seed_positions) {
        storeAction.dispatch(action.PWDENTRY, {
          pwd: null,
          seeds: payload.seed_positions,
        });
      }
      if (payload.pass_seeded_positions && payload.pin_seed_positions) {
        pin_seeds = getRandomSeeds();
        storeAction.dispatch(action.PWDENTRY, {
          pwd: payload.pass_seeded_positions,
          seeds: pin_seeds,
        });
      }
      if (payload.show_fake_seeds) {
        pin_seeds = getRandomSeeds();
        storeAction.dispatch(action.PWDENTRY, {
          pwd: getRandomSeeds(),
          seeds: pin_seeds,
        });
      }

      return new Promise(function (resolve, reject) {
        resolvelater = resolve;
      });
    },
    renderChoiceScreenForm() {
      storeAction.dispatch(action.CHOICE_SCREEN, { show: true });
      return new Promise(function (resolve, reject) {
        resolvelater = resolve;
      });
    },
    renderForm() {
      return new Promise<void>(function (resolve, reject) {
        resolve();
      });
    },
    onContinue() {
      //alert(JSON.stringify(arguments));
    },
    onError(e) {
      console.error('ERROR ' + e);
    },
    endSession() {
      console.log('Session Ended ');
    },
  };
};
CustomUIHandler.prototype.startActivityIndicator = function () {};

CustomUIHandler.prototype.endActivityIndicator = function () {};

CustomUIHandler.prototype.controlFlowStarting = (
  clientContext: object | null
) => {};

CustomUIHandler.prototype.controlFlowActionStarting = (
  clientContext: object | null
) => {};

CustomUIHandler.prototype.controlFlowActionEnded = (
  clientContext: object | null
) => {};

CustomUIHandler.prototype.controlFlowEnded = (
  clientContext: object | null
) => {};

CustomUIHandler.prototype.handlePolicyRedirect = (
  redirectType: com.ts.mobile.sdk.RedirectType,
  policyId: string,
  userId: string,
  additionalParameters: object,
  clientContext: object
): Promise<com.ts.mobile.sdk.RedirectInput> =>
  Promise.resolve(
    com.ts.mobile.sdk.RedirectInput.create(
      com.ts.mobile.sdk.RedirectResponseType.RedirectToPolicy
    )
  );

CustomUIHandler.prototype.controlFlowCancelled = (
  clientContext: object | null
) => {};

export { CustomUIHandler };
