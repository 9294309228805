import { Directive, HostListener, ElementRef, Renderer2, Input, ViewChild } from '@angular/core';

@Directive({
  selector: '[appToolTip]'
})
export class ToolTipDirective {

  @Input() elem : string;
  @ViewChild('ourstore') ourStore : ElementRef;

  elementShown : KeyboardEvent;

  constructor(private render : Renderer2) { }

  @HostListener('window:click',['$event']) onGlobalClick(event){
    if(event && event.target && event.target['id'] && this.elem && event.target['id'] === this.elem ){
      if(event.target.lastChild.style.display !== 'block'){
        this.show(event);
      }else{
        this.hide(event);
      }
    }else{
      if(this.elementShown){
        this.hide(this.elementShown);
      }
    }
  }

  show(event : KeyboardEvent){
    this.render.setStyle(event.target['lastChild'],'display','block');
    this.elementShown = event;
  }

  hide(event : KeyboardEvent){
    this.render.setStyle(event.target['lastChild'],'display','none');
    this.elementShown = null;
  }

}
