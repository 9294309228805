import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor(@Inject(DOCUMENT) private document : Document) { }

  set(key:string,value:string){
    let expirationDate = new Date(+new Date() + 5 * 24 * 60 * 60 * 1000);
    this.document.cookie = `${key}=${value}; expires=${expirationDate.toUTCString()}`;
  }

  get(key:string){
    let cookies = this.document.cookie;
    let found = null;
    cookies.split(';').forEach(cookie=>{
      if(cookie.indexOf(key)>-1){
        found = cookie.split('=')[1];
      }
    })
    return found;
  }

  remove(key:string){
    this.document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  }

}
