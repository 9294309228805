import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ActionTypes {
    public CUSTOMER_TYPE_CHANGE = 'CUSTOMER_TYPE_CHANGE';
    public LOGIN_BTN_SUBMIT = 'LOGIN_CONFIRM_BTN_SUBMIT';
    public LOGIN_CONFIRM_BTN = 'LOGIN_CONFIRM_BTN';
    public REFRESH_LOGIN = 'REFRESH';
    public TITLE = 'TITLE';
    public LOGGED_IN = 'LOGIN';
    public SHOW_OTP = 'SHOW_OTP';
    public SHOW_LOGIN_ERROR = 'SHOW_LOGIN_ERROR';
    public HIDE_LOGIN_ERROR = 'HIDE_LOGIN_ERROR';
    public SHOW_MODAL = 'SHOW_MODAL';
    public SHOW_HIDE_CONSENT = 'SHOW_HIDE_CONSENT';
    public SELECT_ALL_ACCS = 'SELECT_ALL_ACCS';
    public PWDENTRY = 'PWDENTRY';
    public SUBMITPWD = 'SUBMITPWD';
    public SUBMITTOKEN = 'SUBMITTOKEN';
    public ACCESSTOKEN = 'ACCESSTOKEN';
    public STEPUPDATE = 'STEPUPDATE';
    public CHOICE_SCREEN = 'CHOICE_SCREEN';
    public CHOICE_SUBMIT = 'CHOICE_SUBMIT';
    public BROWSER_BIND = 'BROWSER_BIND';
    public BROWSER_BIND_SUBMIT ='BROWSER_BIND_SUBMIT'
}