import { Component, ViewChild, ElementRef } from '@angular/core';
import { StoreService } from 'src/app/services/store/store.service';
import { EnvService } from 'src/app/services/env.service';
import { LoadcmsService } from 'src/app/services/loadcms.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'error-label',
  templateUrl: './error-label.component.html',
  styleUrls: ['./error-label.component.less']
})
export class ErrorLabelComponent {
  @ViewChild('errorElement') error : ElementRef;

  errorDisplay  = false;
  errorMessage  = '';
  loginErrCount : string;
  errorTemplate : any;
  display = false;
  lastAttempt = false;
  generalErr = false;
  errorHeading = '';
  constructor(private store : StoreService, public env : EnvService, private template : LoadcmsService, private sanitizer : DomSanitizer) { 
    this.store.storeCB({
      errordisplay : this.errorDisplayFn.bind(this),
      hideLabel : this.hideError.bind(this)
    })
  }
 extractContent(htmlString) {
    let span = document.createElement('span');
    span.innerHTML = htmlString;
    return (span.textContent || span.innerText).split('.')[0];
  };
      
  errorDisplayFn(display : string){
    if(display !== 'locked'){
      this.errorDisplay = true;
      if(this.template.cms.content['error-label'][display]){
        this.display = false;
        this.lastAttempt = (display === 'unsuccessful4') ? true :false;
        this.generalErr = (display === 'general' || display === 'exceed') ? true :false;
        this.errorHeading = this.extractContent(this.template.cms.content['error-label'][display]);
        this.errorTemplate = this.sanitizer.bypassSecurityTrustHtml(this.template.cms.content['error-label'][display]);
      }else{
        this.display = true;
        this.generalErr = true;
        this.errorHeading = this.extractContent(this.template.cms.content['error-label']['general']);
        this.errorTemplate = this.sanitizer.bypassSecurityTrustHtml(this.template.cms.content['error-label']['general']);
      }
      
      setTimeout(()=>{
        if(this.error && this.error.nativeElement){
            this.error.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      });
    }
}

  hideError(){
    this.errorDisplay = false;
  }

}
