import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store/store.service';
import { ActionTypes } from '../../services/store/actions.service';
import { EnvService } from 'src/app/services/env.service';
import { TimeoutService } from 'src/app/services/timeout.service';
import { LoadcmsService } from '../../services/loadcms.service';


@Component({
  selector: 'app-choice-screen',
  templateUrl: './choice-screen.component.html',
  styleUrls: ['./choice-screen.component.less']
})
export class ChoiceScreenComponent implements OnInit {
 
  betaConfig:any;

  constructor(private store : StoreService, public env : EnvService, private actions : ActionTypes, private timer : TimeoutService,
    private cms : LoadcmsService
    ) {
        this.store.storeCB(this.storeFns());
  }

  ngOnInit() {
    this.betaConfig = this.cms.cms.connections.phoenix.isBetaVersion;
  }
    storeFns(){
      return {
        submitChoice : this.submitChoice.bind(this)
      }
    }
    submitChoice(choice:any){
         this.store.dispatch(this.actions.CHOICE_SUBMIT,{choice});
    }


}
