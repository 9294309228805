import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[appTabKeydown]'
})
export class TabKeydownDirective {
  @Input() attr : string;
  constructor(private element : ElementRef) { }

  @HostListener('keydown',['$event']) onkeydown(event : KeyboardEvent){
    
    if (event.keyCode === 13 || event.keyCode === 32) {
      let child = this.element.nativeElement.querySelector('input');
      let current = child[this.attr];
      child[this.attr] = !current;
    }
  }

}
