import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private errorToastIds = [];

  constructor(
    private toastr: ToastrService,
  ) {
  }

  public showSuccess(message: string, title?: string, options?: any) {
    this.toastr.success(
      message,
      title,
      options
    );
  }

  public showError(message: string, title?: string, options?: any) {
    const id = this.toastr.error(
      message,
      title,
      options
    );

    this.errorToastIds.push(id);
  }

  public showInfo(message: string, title?: string, options?: any) {
    this.toastr.info(
      message,
      title,
      options
    );
  }

  public remove(id: any) {
    this.toastr.remove(id);
  }
}
