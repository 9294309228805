import { Injectable } from '@angular/core';
import { LoadcmsService } from '../loadcms.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  /* 
  Customer type can have values
  "commercial" | "retail" 
  */
  private _customerType: string;

  public get customerType(): string {
    return this._customerType;
  }
  public set customerType(value: string) {
    this._customerType = value;
  }

  /*
  Fraud session id for threat matrix
  */ 
  private _fraudSession: string;

  public get fraudSession(): string {
    return this._fraudSession;
  }
  public set fraudSession(value: string) {
    this._fraudSession = value;
  }

    /*
  Configuration values from content management
  */ 
 private _cms: {'content':any,'connections':{'env':any,'ssp':any,'transmit':any,'edge':any,'phoenix':any}};

 public get cms(): {'content':any,'connections':{'env':any,'ssp':any,'transmit':any,'edge':any,'phoenix':any}} {
    return this._cms;
 }
 public set cms(value: {'content':any,'connections':{'env':any,'ssp':any,'transmit':any,'edge':any,'phoenix':any}}) {
   this._cms = value;
 }


  private _tppParam: string;

  public get tppParam(): string {
    return this._tppParam;
  }
  public set tppParam(value: string) {
    this._tppParam = value;
  }

  /* eslint-disable @typescript-eslint/ban-types */
  private _tppObj: Object;
  public get tppObj(): Object {
    return this._tppObj;
  }
  public set tppObj(value: Object) {
    this._tppObj = value;
  }

  private _mFlowQParamObj: Object;
  public get mFlowQParamObj(): Object {
    return this._mFlowQParamObj;
  }
  public set mFlowQParamObj(value: Object) {
    this._mFlowQParamObj = value;
  }
  /* eslint-enable @typescript-eslint/ban-types */
  private _isMFlow: boolean;
  public get isMFlow(): boolean {
    return this._isMFlow;
  }
  public set isMFlow(value: boolean) {
    this._isMFlow = value;
  }
  
  constructor() { 
    /**
     * Empty constructor
     */
  }

}
