/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StoreService } from '../services/store/store.service';
import { SessionService } from '../services/store/session.service';
import { ActionTypes } from '../services/store/actions.service';
import { CustomUIHandler } from './customUIHandler';
import { CustomTransportProvider } from './customTransportProvider';
import { LoadcmsService } from '../services/loadcms.service';
import { DomService } from '../utils/dom.service';

export interface UIContext {
  viewContainerRef: ViewContainerRef;
  resolver: ComponentFactoryResolver;
}

@Injectable({
  providedIn: 'root'
})

export class TransmitService {
  transmitSDK: com.ts.mobile.sdk.TransmitSDKXm;
  uiHandler : com.ts.mobile.sdk.UIHandler
  public uiContext : UIContext;
  resolvelater : any;

  configValues : any;
  betaConfig : any;

  constructor(
    private http : HttpClient,
    private store : StoreService,
    private session : SessionService, 
    private action : ActionTypes,
    private cms : LoadcmsService,
    private dom : DomService
    ) {}

  public initialize(){
    if(window['xmsdk']){
      this.configValues = this.cms.cms.connections.transmit;
      this.betaConfig = this.cms.cms.connections.phoenix;
      this.transmitSDK = window['xmsdk'].XmSdk();  
      this.transmitSDK.setUiHandler(new CustomUIHandler(this.store,this.action));
      this.transmitSDK.setConnectionSettings(this.getConnectionSettings());
      this.transmitSDK.setTransportProvider(new CustomTransportProvider(this.http,this.store));
      this.transmitSDK.initialize().then((success: boolean) => {}).catch((error: any) => {});
      
  }
  }

  private getConnectionSettings(): com.ts.mobile.sdk.SDKConnectionSettings {
    return com.ts.mobile.sdk.SDKConnectionSettings.create(
                    this.configValues.serverUrl,
                    this.configValues.appId,
                    null,
                    null 
                );

  };

  makeAuthenticateCall(username:string,isIbId:boolean,obj:any): Promise<com.ts.mobile.sdk.AuthenticationResult> {
    return new Promise<com.ts.mobile.sdk.AuthenticationResult>((resolve, reject) => {
      if(!isIbId){
        this.transmitSDK.invokeAnonymousPolicy(this.configValues.loginJourneyNameUs,obj,this.getClientContext())
        .then((authenticationResults: com.ts.mobile.sdk.AuthenticationResult) => {
            resolve(authenticationResults);              
        })
        .catch((error: com.ts.mobile.sdk.AuthenticationError)=>{
            reject(error);
        });
      }else{
        this.transmitSDK.authenticate(username, this.configValues.loginJourneyName, obj , this.getClientContext())
        .then((authenticationResults: com.ts.mobile.sdk.AuthenticationResult) => {
            resolve(authenticationResults);
        })
        .catch((error: com.ts.mobile.sdk.AuthenticationError) => {
            reject(error);
        }); 
      } 
  });
  }
  
  public authenticate(username:string,isIbId:boolean): Promise<com.ts.mobile.sdk.AuthenticationResult> {
    //non-phoenix object - isPhoenixEnable is not present
    const obj = { 
                ib_id:username,
                fraud_session_id:this.session.fraudSession,
                isMobile:this.dom.isMobileDevice(), 
                bind_toggle: this.configValues.bind_toggle,
                isPhoenixEnable: true,
                isBetaVersion:this.betaConfig.isBetaVersion,
                tppName: this.session.tppObj['tpp-name'],
                tppId: this.session.tppObj['tpp-id'] ,
                tppRole: this.session.tppObj['tpp-role'],
                tppCustomerNotPresent : this.session.tppObj['tpp-customer-not-present'],
                //MFLOW related inputs
                use_case: this.session.mFlowQParamObj == null ? null :
                  this.session.mFlowQParamObj['use_case'],
                
                mflow_tpp_params:this.session.mFlowQParamObj == null ? null :{
                    nonce: this.session.mFlowQParamObj['nonce'],
                    //"tpp_jwt": this.session.mFlowQParamObj['tpp_jwt'],
                    scope: [
                      this.session.mFlowQParamObj['scope']
                    ],
                    state: this.session.mFlowQParamObj['state'],
                    //duplicate param of tpp_jwt as requested by Transmit team
                    request: this.session.mFlowQParamObj['tpp_jwt'],
                    client_id: this.session.mFlowQParamObj['client_id'],
                    redirect_uri: this.session.mFlowQParamObj['redirect_uri'],
                    response_type: this.session.mFlowQParamObj['response_type']
                  }
            }
    return new Promise<com.ts.mobile.sdk.AuthenticationResult>((resolve, reject) => {
        this.logout().then(()=>{
          if(!isIbId){
            this.transmitSDK.invokeAnonymousPolicy(this.configValues.loginJourneyNameUs,obj,this.getClientContext())
            .then((authenticationResults: com.ts.mobile.sdk.AuthenticationResult) => {
                resolve(authenticationResults);              
            })
            .catch((error: com.ts.mobile.sdk.AuthenticationError)=>{
                reject(error);
            });
          }else{
            this.transmitSDK.authenticate(username, this.configValues.loginJourneyName, obj , this.getClientContext())
            .then((authenticationResults: com.ts.mobile.sdk.AuthenticationResult) => {
                resolve(authenticationResults);
            })
            .catch((error: com.ts.mobile.sdk.AuthenticationError) => {
                reject(error);
            }); 
          } 
        }).catch(()=>{
          if(!isIbId){
            this.transmitSDK.invokeAnonymousPolicy(this.configValues.loginJourneyNameUs,obj,this.getClientContext())
            .then((authenticationResults: com.ts.mobile.sdk.AuthenticationResult) => {
                resolve(authenticationResults);              
            })
            .catch((error: com.ts.mobile.sdk.AuthenticationError)=>{
                reject(error);
            });
          }else{
            this.transmitSDK.authenticate(username, this.configValues.loginJourneyName, obj , this.getClientContext())
            .then((authenticationResults: com.ts.mobile.sdk.AuthenticationResult) => {
                resolve(authenticationResults);
            })
            .catch((error: com.ts.mobile.sdk.AuthenticationError) => {
                reject(error);
            }); 
          } 
        })
    });
}

public getClientContext(){
  return {
    uiContainer: this.uiContext.viewContainerRef['nativeElement'], // *** used by the default ui handler ***
    viewContainerRef: this.uiContext.viewContainerRef,
    resolver: this.uiContext.resolver
  } 
}

  public unbindUserFromSDK(ibid : string){
    this.transmitSDK.clearDataForUser(ibid);
  }

  public forceCancelTransmitJourney() {
    this.transmitSDK.cancelCurrentRunningControlFlow();
  }

  public logout(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
        this.transmitSDK.logout()
            .then((success: boolean) => {
                resolve(true);
            }).catch(error=>{
                reject();
            })
    });
  }
}
