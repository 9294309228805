<!--The content below is only a placeholder and can be replaced.-->
<div>
  <div [class]="!choice? 'ib-body-bg':''">
    <ib-header></ib-header>
    <div *ngIf="!choice"><app-login></app-login></div>
    <div *ngIf="choice"><app-choice-screen></app-choice-screen></div>
    <ib-footer></ib-footer>
    <err-modal></err-modal>
  </div>
</div>

