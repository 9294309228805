const xmsdk: com.ts.mobile.sdk.TransmitSDKXm = (window as any).xmsdk;
//const transmitSDK = new (xmsdk as any).XmSdk();
import { HttpClient, HttpRequest, HttpParams, HttpResponse, HttpHeaders} from '@angular/common/http';
import { StoreService } from 'src/app/services/store/store.service';


export class CustomTransportProvider implements com.ts.mobile.sdk.TransportProvider {

    constructor(private httpClient: HttpClient, private store: StoreService) {
        
    }

    sendRequest(request: com.ts.mobile.sdk.TransportRequest): Promise<com.ts.mobile.sdk.TransportResponse> {
        return new Promise((resolve, reject) => {
            
            const options: any = {
                body: JSON.parse(request.getBodyJson()),
                responseType: 'json', 
                headers: this.prepareRequestHeaders(request.getHeaders()),
                contentType: 'application/json',
                withCredentials: true,
                observe: 'response'
            }

            this.httpClient.request(
                request.getMethod(),
                request.getUrl(),
                options
            ).subscribe(
                data => this.handleSuccess(request, data).then((resp: any) => {
                    resolve(resp);
                }),
                error => this.handleError(error).catch(reject)
            );

        });
    }
    
    private handleSuccess(request: com.ts.mobile.sdk.TransportRequest, httpResponse: any): Promise<com.ts.mobile.sdk.TransportResponse> {        
        let response = new com.ts.mobile.sdk.TransportResponse();
        
        response.setMethod(request.getMethod());
        response.setStatus(httpResponse.status);

        const transportHeaders = this.parseResponseHeaders(httpResponse.headers);
        response.setHeaders(transportHeaders);

        response.setBodyJson(JSON.stringify(httpResponse.body));

        return Promise.resolve(response);
    }

    private handleError(error: any): Promise<com.ts.mobile.sdk.AuthenticationError> {
        const errorData = error.error;
        const errorMessage = error.error.error_message;

        const AuthenticationError = com.ts.mobile.sdk.AuthenticationError;
        return Promise.reject(AuthenticationError.createApplicationGeneratedCommunicationError(errorMessage, errorData));
    }

    /***  Headers  */
    /*** --------- */

    private parseResponseHeaders(headers: any): com.ts.mobile.sdk.TransportHeader[] {
        const transportHeaders: com.ts.mobile.sdk.TransportHeader[] = [];
        const headerKeys = headers.keys();
        
        headerKeys.forEach((key: any) => {
            const header = new com.ts.mobile.sdk.TransportHeader();
            header.setName(key);
            header.setValue(headers.get(key));
            transportHeaders.push(header);
        });

        return transportHeaders
    }

    private prepareRequestHeaders(headers: Array<com.ts.mobile.sdk.TransportHeader>): com.ts.mobile.sdk.TransportHeader[] {
        let transformedHeaders: any = {};
        headers.forEach((header) => {
            transformedHeaders[header.getName()] = header.getValue()
        });

        /**
         *  Access this header in your policy by:
         *  "Custom header value: ${@policy.request().headers.custom_header}"
         */
        
        //transformedHeaders['Access-Control-Allow-Headers'] = 'Authorization, X-PING';
    
        return transformedHeaders;
    }
}