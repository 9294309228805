
<div class="ib-locked">
  <div class="">
    <div class="locked-error-band" role="dialog" aria-labelledby="">
      <div class="">
        <div class="" fxLayout="column" fxLayoutAlign="space-around">
          <div class="locked-header">
            <mat-icon svgIcon="ib_info-alert-22" class="vertical-align-top ib-info-icon"></mat-icon>
            <span class="">Your account is currently locked. </span>
          </div>
          
          <div class="locked-content" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="10px">
            <p>If you've forgotten your security details you can reset them here:</p>

            <div class="width-full">
              <a [href]="template.cms.connections.ssp+'/update/'" target="_blank" rel="noopener noreferrer"
              class="ib-link-row" aria-label="Reset password">Reset password</a>
            </div>
            <div class="width-full">
              <a [href]="template.cms.connections.ssp+'/update/securitynumber'" target="_blank" rel="noopener noreferrer"
              class="ib-link-row" aria-label="Reset security number">Reset security number</a>
            </div>
            <div class="width-full">
              <a [href]="template.cms.connections.ssp+'/enrol-view-name'" target="_blank" rel="noopener noreferrer"
              class="ib-link-row" aria-label="Forgotten both and need to re-register">Forgotten both and need to re-register</a>
            </div>
          </div>
          <div class="ib-ibid-button" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between center">            
            <div >
                <button mat-flat-button color="primary" class="go-home-page" type="submit" (click)="reLogin()" aria-label="Go Home page"
                value="reLogin"> Go back to the homepage</button> 
            </div>
      </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div>

    
</div>