import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SessionService } from '../services/store/session.service';
import { LoadcmsService } from '../services/loadcms.service';

@Injectable({
  providedIn: 'root'
})
export class DomService {

  constructor(
    @Inject(DOCUMENT) private document : Document,
    private session : SessionService,
    private cms : LoadcmsService
    ) { }

  getLocationParams(){
    return this.document.location.search;
  }

  getSearchParams(){
    return new URL(location.href).searchParams;
  }
  
  getTppParams() {
    let params = new URL(location.href).searchParams;
    let tppObj = {};
    if(params){
      tppObj['tpp-id'] = params.get('X-TPP-Id');
      tppObj['tpp-name'] = params.get('X-TPP-Name');
      tppObj['tpp-role'] = params.get('X-TPP-Role');
      tppObj['tpp-customer-not-present'] = params.get('X-TPP-CustomerNotPresent');
    }
    return tppObj;
  }

  //MFLOW Changes
  getMFlowQParams(){
    let qParams = this.getSearchParams();
    let mFlowQParamObj = {};
    if(qParams){
      mFlowQParamObj['use_case'] = qParams.get('use_case');
      mFlowQParamObj['nonce'] = qParams.get('nonce');
      mFlowQParamObj['tpp_jwt'] = qParams.get('request');
      mFlowQParamObj['scope'] = qParams.get('scope');
      mFlowQParamObj['state'] = qParams.get('state');
      mFlowQParamObj['request'] = qParams.get('request');
      mFlowQParamObj['client_id'] = qParams.get('client_id');
      mFlowQParamObj['redirect_uri'] = qParams.get('redirect_uri');
      mFlowQParamObj['response_type'] = qParams.get('response_type');
    }
    return mFlowQParamObj;
  }

  //MFLOW Changes
  isMFlowSSOLogin(){
    let qParams = this.getSearchParams();
    return  qParams.get('origin') && qParams.get('origin').toLowerCase() === 'mflow_sso';
  }

  redirectToURL(url : string){
    this.document.location.href = url;
  }

  isTimedoutEDGE(){
    return (this.document.location.search.indexOf('SessionTimedOut') >= 0);
  }

  redirectToSso(){
    this.document.location.search = '';
  }

  deviceProfiling(elem){
    this.session.fraudSession = this.generateSession();
    let orgId = this.cms.cms.connections.transmit.tmxorg;
    let element = elem;
    element.id = 'tulipstag';
    element.type = 'text/javascript';
    element.src = 'https://tulips.metrobankonline.co.uk' + '/fp/tags.js?org_id='+ orgId + '&session_id=' + this.session.fraudSession;
    return {body:this.document.body,element};
  }

  /* eslint-disable */
  generateSession(){
    let dt = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c)=> {
        let r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
  }
  /* eslint-enable */

  isMobileDevice() {
    const mobMaxWidth = 991;
    const deviceTypes = [
        /iPhone|iPad|iPod/i,
        /Android/i,
        /BlackBerry/i,
        /Opera Mini/i,
        /IEMobile/i,
        /MeeGo/i,
        /webOS/i,
        /Windows Phone/i
    ];
    if(navigator.userAgent){
        for (let i = 0, l = deviceTypes.length; i < l; i++) {
            if (navigator.userAgent.match(deviceTypes[i])) {
                return true;
            }
        }
    }else{
        return window.innerWidth < mobMaxWidth;
    }
    return false;
}
}
