import { Injectable } from '@angular/core';
import { Debouncer } from '../utils/debouncer';
import { StoreService } from './store/store.service';
import { ActionTypes } from './store/actions.service';

@Injectable({
  providedIn: 'root'
})
export class TimeoutService {

  //private TWO_MINUTES : number = 120000;
  private FIVE_MINUTES  = 300000;
  private timer = this.FIVE_MINUTES;
  deb : any;

  constructor(private store : StoreService, private actions : ActionTypes) {
    this.deb = new Debouncer();
  }
  
  throttleTimeout(){
    this.deb.debounce(this.timeoutError.bind(this),this.timer);
  }

  changetimeout(){
    this.timer = this.FIVE_MINUTES
    this.throttleTimeout();
  }

  timeoutError(){
    this.store.dispatch(this.actions.SHOW_MODAL,{
      display:true,
      title:'',
      description:'',
      errortype:'TIMEOUT',
      redirect:''
    });
  }

}
