
<div *ngIf="!hideOnBind" class="mar-t-50">
  <div #transmitContainer style="display: none"></div>
  <error-label></error-label>

<main id="main">
  <div *ngIf="!otpPage && !pwdPage" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">

    <mat-card class="mat-card-login mar-t-50" fxLayout="column" fxLayoutGap="1px">
      <div fxLayout="column" fxLayoutAlign="space-around center" > 
        <mat-icon svgIcon="ib_logo" class="ib_logo"></mat-icon>
        <h1 class="login-title">Welcome to Metro Bank Online Banking</h1>
      </div>
      
      <div class="login-seperator"></div>

      <form #form="ngForm" name="loginForm" autocomplete="off" novalidate=""  class="ib-ibid-form">
        <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
          <div class="width-full text-center" (click)="customernumberGa()">  
            <a [href]="content.cms.connections.ssp+'/enrol-view-name'" target="_blank" rel="noopener noreferrer"
            class="ib-link-row registration-link" aria-label="Register for online banking">Register for online banking</a>
          </div>

          <mat-label class="ib-id-label">
            Enter your 12-digit customer number or username
            <button id="tooltip-btn"
                    aria-label="additional information"
                    type="button"
                    class="secondary-btn"
                    #tooltipUser="matTooltip"
                    (click)="tooltipUser.toggle()"
                    [matTooltipClass]="'ib-tooltip'"
                    matTooltip="You received your 12-digit customer number when you opened your account. This number is used for Online Banking, Telephone Banking. If you have chosen an alternative username please enter that.">
            <mat-icon svgIcon="ib_hint" class="question-icon"></mat-icon>
            </button>
          </mat-label>        
          <input #ibidinput
                autocomplete="username"
                type="text"
                [class]="((form.submitted || ibid.touched) && (ibid.invalid || ibidInvalid))?'ibid ib-ibid__err md-text':'ibid ib-ibid md-text'"
                name="username"
                type="text"
                id="USER_NAME"
                [(ngModel)]="username"
                #ibid="ngModel"
                maxlength="18"
                aria-label="12-digit customer No.or username"
                required pattern="[a-zA-Z0-9_]{1,18}$" />
                <div class="customer-helptext">(Your customer number should only include numbers)</div>
          <p *ngIf="(form.submitted || ibid.touched) && ibid.invalid" class="err-msg " role="alert">Please enter your 12-digit customer number or username</p>
          <!-- CR86 -->
          <p *ngIf="(form.submitted || ibid.touched) && ibidInvalid && !ibid.invalid" role="alert" class="err-msg">Incorrect details.Please enter your correct 12-digit customer number or username</p>

          <div class="width-full text-center" (click)="customernumberGa()">  
            <a [href]="content.cms.connections.ssp+'/update/customer-no-reminder'" target="_blank" rel="noopener noreferrer"
            class="ib-link-row" aria-label="Forgotten your customer number or username">Forgotten your customer number or username?</a>
          </div>
      </div>

      <div class="row ib-link-div" >
        <div class="col-xs-12">
          <mat-checkbox color="primary" class="ib-remember-me"  id="remember" name="remember" [(ngModel)]="saveID">
            <label for="remember">Remember me (Don't tick if you are on a shared computer)</label>
          </mat-checkbox>
        </div>
      </div>

        <div class="ib-ibid-button" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between center">            
                <div ngClass.xs="width-100 text-center" >
                    <span *ngIf="transmitLoader" aria-busy="true" ngClass.xs="text-center">
                        <img class="btn-loader" alt="loading" [src]="env.loader">
                    </span>
                    <button mat-flat-button
                            color="primary"
                            class="continue"
                            type="submit"
                            id="btn-submit"
                            (click)="form.valid && submitID()"
                            aria-label="Continue"
                            value="Continue"
                            ngClass.xs="width-100">Continue</button>
                </div>
          </div>
      </form> 
      
    </mat-card>
  </div>


  <div *ngIf="pwdPage && !otpPage" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <!-- Password Form -->
    <mat-card class="ib-password-container mar-t-50" >
      <div  fxLayout="column" fxLayoutAlign="space-around center" > 
        <mat-icon svgIcon="ib_logo" class="ib_logo"></mat-icon>
        <h1 class="login-title ">Log in to Metro Bank Online Banking</h1>
      </div>
      <div class="login-seperator"></div>
      <div class="ib-ibid-form">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 class="margin-0"><label class="user-label padding-16">Your customer number or username</label></h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding-16">
              <span class="ibid-display">{{this.username}}</span>
            </div>
          </div>
      <form #pwdForm="ngForm" name="loginForm" autocomplete="off" novalidate="">
        <div>
          <div class="mar-t-20">
            <div class="ib-login-form">
              <div>
                <div *ngIf="!locked">
                    <div class="">
                        <div>
                              <div>
                                <h2 class="margin-0"><mat-label class="user-label" aria-label="Your password">Your password
                                  <button aria-label="additional information" type="button" #tooltipPass="matTooltip" (click)="tooltipPass.toggle()"  class="secondary-btn" [matTooltipClass]="'ib-tooltip'" 
                                  matTooltip="This is the password you created when you registered for Online Banking. It will be a minimum of 7 characters and contain upper or lower case letters as well as a number.">
                                  <mat-icon svgIcon="ib_hint"  
                                  class="question-icon"></mat-icon>
                                        </button>
                                      </mat-label></h2>
                                <div class="mar-t-10 full-width">
                                    <div class="mar-t-10">
                                      <input [class]="(pwdForm.submitted && passseed0.invalid)?'ib-password__err ib-password':'ib-password__selected ib-password'"
                                      [disabled]="transmitLoader" #passseed0="ngModel" name="password0" id="password0"
                                      [(ngModel)]="passwordValue" autocomplete="current-password"
                                      autocapitalize="none" type="password"
                                      aria-label="Your password" required>
                                    </div>
                                </div>
                                <label
                                  *ngIf="pwdForm.submitted && ( passseed0 .invalid )"
                                  class="err-msg" role="alert">Your Password is required</label>
                                <div>
                                  <div class="mar-t-10">
                                    <!-- /update-password-view-name -->
                                    <a [href]="content.cms.connections.ssp +'/update/'"
                                      aria-label="Forgotten your Password" rel="noopener noreferrer" id="forgot-password-link" class="ib-link-row"
                                      target="_blank">Forgotten your password ?</a>
                                  </div>
                                </div>
                              </div>
                          <div class="">
                            <div>
                              <br>
                              <fieldset>
                                <legend class="margin-0" aria-describedby=”legend”>
                                  <h2 class="margin-0"><mat-label aria-label="Your security number" class="user-label" for="">
                                Your security number
                                  <button aria-label="additional information" type="button" #tooltipSec="matTooltip" (click)="tooltipSec.toggle()" class="secondary-btn" [matTooltipClass]="'ib-tooltip'" 
                                  matTooltip="This is the 8-digit number you created when you registered for Online Banking.">
                                  <mat-icon svgIcon="ib_hint"  
                                  class="question-icon"></mat-icon>
                                        </button>
                                </mat-label></h2>
                            </legend>
                            <p id=”legend” class="seeds-dialog">
                                Please enter the {{securityPositions[0].position}}, 
                               {{securityPositions[1].position}} and 
                                {{securityPositions[2].position}}  characters (should only include numbers).
                            </p>
                              <div class="mar-t-10 full-width"  fxLayout="row" fxLayoutAlign="space-between center" >
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 ib-seed-cover">
                                  <div class="mar-t-10">
                                      <div [class]="(pwdForm.submitted && security0.invalid)?'ib-seed-inner_err ib-seed-inner':'ib-seed-inner'">
                                        <input #sec0
                                               name="security0"                                               
                                               type="password"
                                               id="security0"
                                               [disabled]="transmitLoader"
                                               #security0="ngModel"
                                               autocomplete="off"
                                               [class]="((securityPositions[0].value || securityPositions[0].value==='0')?'ib-sec__selected ib-sec':
                                               ((pwdForm.submitted && security0.invalid)) ?'ib-sec ib-sec__err':'ib-sec')"
                                               aria-label="Your security number"
                                               [(ngModel)]="securityPositions[0].value"
                                               required maxlength="1">
                                      </div>
                                  </div>  
                                  <label class="ib-seed-pos" for="security0">{{securityPositions[0].position}} character</label>
    
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 ib-seed-cover">
                                  <div class="mar-t-10">
                                    <div [class]="(pwdForm.submitted && security1.invalid)?'ib-seed-inner_err ib-seed-inner':'ib-seed-inner'">
                                      <input [class]="((securityPositions[1].value || securityPositions[1].value==='0')?'ib-sec__selected ib-sec':
                                      ((pwdForm.submitted && security1.invalid)) ?'ib-sec ib-sec__err':'ib-sec')"
                                      autocomplete="off" type="password"
                                      [disabled]="transmitLoader" #security1="ngModel" name="security1" id="security1"
                                      [(ngModel)]="securityPositions[1].value" required maxlength="1">  
                                    </div>
  
                                  </div>
                                  <label class="ib-seed-pos" for="security1">{{securityPositions[1].position}} character</label>
  
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 ib-seed-cover">
                                  <div class="mar-t-10">
                                      <div [class]="(pwdForm.submitted && security2.invalid)?'ib-seed-inner_err ib-seed-inner':'ib-seed-inner'">
                                        <input [class]="((securityPositions[2].value || securityPositions[2].value==='0')?'ib-sec__selected ib-sec':
                                        ((pwdForm.submitted && security2.invalid)) ?'ib-sec ib-sec__err':'ib-sec')"
                                         autocomplete="off" type="password"
                                        [disabled]="transmitLoader" #security2="ngModel" name="security2" id="security2"
                                        [(ngModel)]="securityPositions[2].value" required maxlength="1">  
                                      </div>
                                  </div>
                                  <label class="ib-seed-pos"for="security2">{{securityPositions[2].position}} character</label>
                                </div>
                              </div>
                              <label *ngIf="pwdForm.submitted && (security0.invalid || security1.invalid || security2.invalid)"
                                class="err-msg" role="alert" >Your Security Number is required</label>
                              <div>
                                <div ngClass.xs="mar-t-20" ngClass.md="mar-t-10" ngClass.lg="mar-t-10" >
                                  <!-- /update-security-number-view-name -->
                                  <a [href]="content.cms.connections.ssp + '/update/securitynumber'"
                                    rel="noopener noreferrer" class="ib-link-row" target="_blank" id="forgot-security-link"
                                    aria-label="Forgotten your security number">Forgotten your security number ?</a>
                                </div>
                              </div>
                            

                              <div class="link-back-color">
                                <div ngClass.xs="mar-t-20" ngClass.md="mar-t-10" ngClass.lg="mar-t-10" >
                                  <!-- /update-security-number-view-name -->
                                  <a [href]="content.cms.connections.ssp + '/enrol-view-name'"
                                    rel="noopener noreferrer" class="ib-link-row" target="_blank" id="forgot-both-link"
                                    aria-label="I have forgotten my password and security number">
                                    I have forgotten my password and security number</a>
                                </div>
                              </div>
                            
                              </fieldset>
                              </div>
                          </div>

                        </div>
                      </div>
                </div>

                <div class="mar-t-10 mar-b-40">
                    <div class="ib-ibid-button" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between center">
                        <div *ngIf="!locked" class="" ngClass.xs="width-100">
                          <div class="mar-t-10" ngClass.xs="width-100 text-center" id="BUT_757E5CE63630B7EB51350">
                            <span *ngIf="transmitLoader" ngClass.xs="text-center" aria-busy="true">
                              <img class="btn-loader" alt="loading" [src]="env.loader">
                            </span>
                            <button mat-flat-button color="primary" class="continue ibid-btn" type="submit"  (click)="submitLoginForm()"
                            aria-label="Log in" ngClass.xs="width-100">Log in</button> 
                          </div>
                        </div>
                        <div class="">
                            <button mat-flat-button (click)="cancelLogin()" aria-label="Cancel log in" class="continue-sec ib-link-btn"
                              target="_blank">Cancel log in</button>
                          </div>
                      
                    </div>
                    
                    
                </div>
              </div>
  
            </div>
          </div>
  
        </div>
      </form>
    </div>
    </mat-card>
  </div>
  </main>
</div>


