import { Component, OnInit } from '@angular/core';
import { EnvService } from 'src/app/services/env.service';

@Component({
  selector: 'ib-footer',
  templateUrl: './ib-footer.component.html',
  styleUrls: ['./ib-footer.component.less']
})
export class IbFooterComponent implements OnInit {
 
  constructor(public env : EnvService) { }

  ngOnInit() {
    /***
     * empty ngOnInit
     */
  }

}
