import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class LoadcmsService {
  cms : {'content':any,'connections':{'env':any,'ssp':any,'transmit':any,'edge':any,'phoenix':any,'beta':any,'mflow':any}}={
    content:{
    },
    connections:{
      env:'',
      ssp:'',
      transmit:'',
      edge:'',
      phoenix:'',
      beta:'',
      mflow:''
    }
  }; 

  constructor(private http: HttpClient, private env: EnvService) { }

  loadcms() :Promise<any>{
    return this.http.get(this.env.configJsonUrl)
            .toPromise()
            .then(data=>{
              Object.assign(this.cms,data);
              return data;
            })
  }
}
