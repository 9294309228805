import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  mbLogo : string = environment.baseHref+'assets/images/metrobank-logo.png';
  errAlert : string = environment.baseHref+'assets/images/icon-40-alert-error.png';
  loader : string = environment.baseHref+'assets/images/loader-30-blue.gif';
  listBullet : string = environment.baseHref+'assets/images/list-bullet.png';
  cookieIcon : string = environment.baseHref+'assets/images/cookie-icon.svg';
  fscs : string = environment.baseHref+'assets/images/FSCSLeaderBanner.jpg';
  configJsonUrl : string = environment.baseHref+'config/config.json';
  newTab : string = environment.baseHref+'assets/images/new-tab-icon.svg';
  fscsChoice : string = environment.baseHref+'assets/images/fcfs-choice.png'

  constructor() {
    /**
     * empty constructor
     */
   }
}
