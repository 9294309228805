<div class="login-info">
  <div class="login-info-title">First time logging in?</div>
  <div class="mar-t-10">
      <div class="login-info-sub-title">
        You will need these security details to log in to Online Banking:
      </div>
      <ul style="padding-inline-start: 1.5em">
          <li class="login-info-point">
              Your Customer Number or username
            <a class="login-info-point__question" appToolTip [elem]="'uname'" id="uname">
                What is this?
                <div style="display: none" class="dropdown-menu">
                  <div class="dropdown-arrow">
                  </div>
                  <div class="tool-tip-msg">
                    <strong>Customer Number or Username</strong>
                  </div>
                  <div class="mar-t-10 tool-tip-msg">
                    Your Customer Number which is used for Internet Banking, Telephone and Automated Banking
                    will be a 12 digit number which would have been presented to you when you opened your
                    Account. If you have chosen an alternative this will be a Username.
                  </div>
                </div>
              </a>
          </li>
          <li class="login-info-point">
            Your password
            <a class="login-info-point__question" appToolTip [elem]="'pwd'" id="pwd">
                What is this?
                <div style="display: none" class="dropdown-menu">
                  <div class="dropdown-arrow">
                  </div>
                  <div class="tool-tip-msg">
                    <strong>Password</strong>
                  </div>
                  <div class="mar-t-10 tool-tip-msg">
                    This is the password you created when you registered for Internet Banking; this will be a
                    minimum of 7 characters and could contain upper and lower case letters as well as a number.
                  </div>
                </div>
              </a>
          </li>
          <li class="login-info-point">
            Your security number
            <a class="login-info-point__question" appToolTip [elem]="'secnum'" id="secnum">
                What is this?
                <div style="display: none" class="dropdown-menu">
                  <div class="dropdown-arrow">
                  </div>
                  <div class="tool-tip-msg">
                    <strong>Security Number</strong>
                  </div>
                  <div class="mar-t-10 tool-tip-msg">
                    This is the 8 digit security number you created when you registered for Internet Banking.
                  </div>
                </div>
              </a>
          </li>
      </ul>
  </div>
  <div class="mar-t-20">
    <!-- <div class="login-info-title">I've forgotten my details</div> -->
    <div class="mar-t-10">
        <div class="login-info-sub-title">
          If you have forgotten your security number or password,<a [href]="content.cms.connections.ssp"
          rel="noopener noreferrer" class="login-info-point__link" target="_blank"> click here</a>. 
          If you have forgotten your 12-digit customer number or can’t log in, call us on 0345 08 08 500.  
        </div>
        <!-- <ul style="padding-inline-start: 1.5em">
          <li class="login-info-point">
            <a [href]="content.cms.connections.ssp""
            rel="noopener noreferrer" class="login-info-point__link" target="_blank">
              If you're missing your security number <strong>or</strong> password
            </a>
          </li>
          <li class="login-info-point">
              <a [href]="content.cms.connections.ssp"
              rel="noopener noreferrer" class="login-info-point__link" target="_blank">
                If you're missing your security number <strong>and</strong> password
              </a>
          </li>
        </ul>  -->
    </div>
  </div>  
  <div class="mar-t-10">
    <div class="login-info-point">
      New to Online Banking?
      <!-- enrol-view-name -->
        <a [href]="content.cms.connections.ssp" target="_blank"
              rel="noopener noreferrer" class="login-info-point__link" aria-label="New to Internet Banking click here">
              Register here
        </a>
    </div>
  </div>

</div>