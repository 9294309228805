import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Toast} from 'ngx-toastr';
export interface IToastButton {
  id: string;
  title: string;
}

@Component({
  /* eslint-disable */
  selector: '[toast-component]',
  /* eslint-enable */
  styleUrls: ['./toast.component.less'],
  templateUrl: './toast.component.html',
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
      })),
      transition('inactive => active', animate('400ms cubic-bezier(0.65, 0, 0.35, 1)', keyframes([
        style({
          // transform: 'translate3d(0, -150%, 0) scale(0.9)',
          opacity: 0,
        }),
        style({
          // transform: 'none',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('400ms cubic-bezier(0.65, 0, 0.35, 1)', keyframes([
        style({
          opacity: 1,
        }),
        style({
          // transform: 'translate3d(0, 20%, 0) scale(1)',
          opacity: 0,
        }),
      ]))),
    ]),
    // fadeAnimation
  ],
  preserveWhitespaces: false,
})
export class ToastComponent extends Toast {
}
