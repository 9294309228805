import { Component, OnInit } from '@angular/core';
import { EnvService } from 'src/app/services/env.service';

@Component({
  selector: 'edge-footer',
  templateUrl: './edge-footer.component.html',
  styleUrls: ['./edge-footer.component.less']
})
export class EdgeFooterComponent implements OnInit {
  date : number;
  constructor(public env : EnvService) { }

  ngOnInit(){
    const d = new Date();
    this.date = d.getFullYear();                  
  }
}
