import { Component} from '@angular/core';
import { EnvService } from 'src/app/services/env.service';
import { StoreService } from 'src/app/services/store/store.service';
import { ActionTypes } from 'src/app/services/store/actions.service';
import { DomService } from 'src/app/utils/dom.service';
import { LoadcmsService } from 'src/app/services/loadcms.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
@Component({
  selector: 'error-label-lock',
  templateUrl: 'error-label-lock.component.html',
  styleUrls: ['./error-label-lock.component.less']
})
export class ErrorLabelLockComponent{
  constructor(public env : EnvService,
    private store : StoreService,
    private action : ActionTypes,
    private dom : DomService,
    public template : LoadcmsService,
    public googleAnalytics: GoogleAnalyticsService
  ) { 
  }

  reLogin(){
    this.store.dispatch(this.action.REFRESH_LOGIN);
    this.dom.redirectToSso();
  }

}
