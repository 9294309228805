import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { get } from 'lodash';

import { ConfigService } from '../services/rest/config.service';

@Injectable({
  providedIn: 'root'
})

export class FeatureTogglesService {
  loaded$ = new BehaviorSubject(false);
  private featureFlags = {};

  constructor(private configService: ConfigService) { }

  private getFeatureStatus(config: object, featureName: string | []) {
    const feature = get(config, featureName, null);
    return !config
      ? { activated: false }
      : { activated: feature.activated };
  }

  public isOn(featureName: string | string[]) {
    if (Array.isArray(featureName)) {
      return featureName.every((featureFlag) => {
        const featureToggle = this.featureFlags[featureFlag];
        return featureToggle && featureToggle.activated;
      });
    }

    const feature = this.featureFlags[featureName];

    return Object.values(this.featureFlags).length === 0
      ? false
      : feature && feature.activated;
  }

  public isOff(featureName: string | string[]) {
    return !this.isOn(featureName);
  }

  private computeFeatureFlags(config: object): object {
    return Object.keys(config).reduce((acc, group) => {
      Object.keys(config[group]).forEach((feature) => {
        const key = `${group}.${feature}`;
        const status = this.getFeatureStatus(config, key);
        acc[key] = status;
      });
      return acc;
    }, {});
  }

  private initializeConfig(config: any) {
    this.featureFlags = this.computeFeatureFlags(config);
    this.loaded$.next(true);
  }

  public init(): Observable<boolean> {
    this.featureFlags = [];
    const featuresConfig = this.configService.getConfig();
    this.initializeConfig({ ...featuresConfig });

    return this.loaded$;
  }

}
