import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { EnvService } from './services/env.service';
import { StoreService } from './services/store/store.service';
import { LoadcmsService } from './services/loadcms.service';
import { IconProviderService } from './services/icons/icon-provider.service';
import { FeatureTogglesService } from './services/feature-toggles.service';
import { CookieConsentCategory, getHeapScript, injectHeadScript } from './utils/script.utils';
import { interval, Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { WINDOW_TOKEN } from './app.injectionTokens';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  choice  = false;
  public ScriptUtility = {
    injectHeadScript: injectHeadScript,
    getHeapScript: getHeapScript,
    CookieConsentCategory: CookieConsentCategory  
  };

  cookieConsentChange$ = interval(100).pipe(
    filter(() => this.window.OneTrust),
    take(1),
    tap(() => this.window.OneTrust.OnConsentChanged(() => this.checkConsentAndInjectHeap()))
  );

  private _unsubscribe$ = new Subject();

  constructor(
    @Inject(WINDOW_TOKEN) public readonly window: Window,
    public env: EnvService,
    private store: StoreService,
    private iconProvider: IconProviderService,
    private features: FeatureTogglesService
  ) {
    // Start icon provider service
    this.iconProvider.init();
    this.store.storeCB(this.storeFns());
    this.features.init();
  }
  ngOnDestroy(): void {
    this._unsubscribe$.next(null);
    this._unsubscribe$.complete();
  }
  ngOnInit(): void {
    this.checkConsentAndInjectHeap();
    this.cookieConsentChange$.pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe();
  }

  storeFns() {
    return {
      showChoice : this.showChoice.bind(this)
    }
  }
  
  showChoice(choice:any){
    this.choice = choice.showChoice;
  }

  checkConsentAndInjectHeap() {
    setTimeout(() => {
      if(this.window.OnetrustActiveGroups?.split(',').includes(this.ScriptUtility.CookieConsentCategory.Performance)) {
        this.ScriptUtility.injectHeadScript(document, this.ScriptUtility.getHeapScript(window['HEAP_APP_KEY']), 'heap');
      }
    }, 500);
  }
}
