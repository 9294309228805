import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { LoadcmsService } from '../services/loadcms.service';

declare global {
  interface Window { dataLayer: any[]; }
}
// eslint-disable-next-line @typescript-eslint/ban-types
declare let ga: Function;

@Injectable({
  providedIn: 'root'
})

export class GoogleAnalyticsService {

  DEFAULT_ENV = {
    setting: this.cms.cms.connections.env,
    appType: 'web'
  };

  constructor(
    private router: Router,
    private cms : LoadcmsService ) {}

  sendButtonClickEvent(params, subSection, section?) {
      const data = {
        attributes: {
          ...params.attributes,
          eventName: 'buttonClick'
        },
        event: 'buttonAction'
      };
  
      this.sendAnalyticsData(data, subSection, section);
  }

  sendGoalEvent(params, subSection, section?) {
      const data = {
        attributes: {
          ...params.attributes,
          eventName:'goals'
        },
        event:'goals'
      };
  
      this.sendAnalyticsData(data, subSection, section);
  }
  
  sendPageLoadedEvent(params, subSection, section?) {
      const url = window.location.href;
      const arr = url.split('/');
      params.page.pagePath = this.router.url;
      params.page.pageURL = `${arr[0]}//${arr[2]}${params.page.pagePath}`;

      const data = {
        user: {
          entityId: null,
        },
        page: {
          pageURL: params.page.pageURL,
          pageName: params.page.pageName,
          pagePath: params.page.pagePath
        },
        event: 'pageLoaded'
      };
  
      this.sendAnalyticsData(data, subSection, section);
  }

  sendNotificationEvent(params, subSection, section?) {
      const data = {
        attributes: {
          ...params.attributes,
          eventName: 'notification'
        },
        event: 'notification'
      };
  
      this.sendAnalyticsData(data, subSection, section);
  }
  

  sendAnalyticsData(param, subSection, section) {
      const payload = {
        environment: {
          ...this.DEFAULT_ENV,
          timestamp: new Date().toISOString(),
          siteSection : section ? section : 'sso',
          siteSubSection: subSection
        },
        ...param
      };
      if (payload.environment.siteSection === 'sso' && payload.event === 'pageLoaded') {
          payload.user = {
            ...payload.user,
            entityId: null
          };
        payload.user = {
          ...payload.user,
           userType: null
        };
      }
      window.dataLayer.push(payload);
  }

}