import { ToastComponent } from '../../src/app/components/toast/toast.component';

export const toastrModuleConfig = {
  disableTimeOut: false,
  timeOut: 5000,
  newestOnTop: true,
  positionClass: 'toast-top-right',
  toastComponent: ToastComponent,
  tapToDismiss: false,
  closeButton: true,
  progressBar: true,
  maxOpened: 3,
  autoDismiss: true
};
