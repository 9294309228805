import { Component, OnInit, Renderer2 } from '@angular/core';
import { DomService } from 'src/app/utils/dom.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  
  constructor(private dom : DomService,public renderer2 : Renderer2,public googleAnalytics: GoogleAnalyticsService) { }

  customerForm : string;
  customerType : string;

  loggedInUser : boolean;

  ngOnInit() {
    this.googleAnalytics.sendPageLoadedEvent(
      {
        page: {
          pageName: 'SSO Login Page'
         }
     },
     'login',
     'sso' 
     );
    const dom = this.dom.deviceProfiling(this.renderer2.createElement('script'));
    this.renderer2.appendChild(dom.body,dom.element);
  }


}
