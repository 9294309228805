import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActionTypes } from './actions.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  private state : BehaviorSubject<{type:string,value:any}> = new BehaviorSubject({type:'',value:''});

  private fns : any = {};

  private callCB(property:string,params?){
    if(this.fns[property]){
      if(params)
        {this.fns[property](params)}
      else
        {this.fns[property]();}
    }
  }

  constructor(private session : SessionService, private action : ActionTypes) {
    this.state.subscribe(state=>(
      this.storeActions(state)
    ));
   }

  storeActions(state : {type:string,value:any}){
    switch(state.type){

      case this.action.SUBMITPWD:{
        this.callCB('submitpwdseeds',state.value);
        break;
      }

      case this.action.PWDENTRY:{
        this.callCB('pwdseeds',state.value);
        break;
      }

      case this.action.SUBMITTOKEN:{
        this.callCB('submitotphardtoken',state.value);
        break;
      }

      case this.action.REFRESH_LOGIN:
        //to re-instantiate login fields
           this.callCB('loginrefresh');
           break;

      case this.action.CUSTOMER_TYPE_CHANGE:{
        //to focus/re-focus on ibid field and blur out other fields
           this.callCB('showotp',{showOtp:false,customerType:null});
           this.session.customerType = state.value;
           break;
      }

      case this.action.SHOW_OTP:{
        //to show/hide otp/hardtoken fields
           this.callCB('showotp',{
             showOtp:state.value.show,
             customerType:this.session.customerType,
             mobile:state.value.mobile,
             landline:state.value.landline,
             fake:state.value.fake,
             exceeded:state.value.exceeded
            });
           if(state.value.show){
             this.callCB('hideOnOtp');
           }
           break;
      }

      case this.action.SHOW_LOGIN_ERROR:{
        //display error on login
             this.callCB('errordisplay',state.value);
             break;
      }

      case this.action.HIDE_LOGIN_ERROR:{
        //display error on login
             this.callCB('hideLabel',state.value);
             break;
      }

      case this.action.STEPUPDATE:{
        //display modal on error and other information
             this.callCB('stepupdate',state.value);
             break;
      }

      case this.action.SHOW_MODAL:{
        //display modal on error and other information
             this.callCB('displaymodal',state.value);
             break;
      }
      case this.action.BROWSER_BIND:{
        this.callCB('showBrowserBind',state.value);
        if(state.value.show){
          this.callCB('hideOnBrowserBind');
          this.callCB('hideOtpOnBrowserBind');
        }
        break;
      }
      case this.action.BROWSER_BIND_SUBMIT:{
        this.callCB('submitBrowserBind',state.value);
        break;
      }
      case this.action.CHOICE_SCREEN:{
        this.callCB('showChoice',{
            showChoice:state.value.show
        });
        break;
      }
      case this.action.CHOICE_SUBMIT:{
            this.callCB('submitchoiceselection',state.value);
                  break;
            }
            
      default : break;
    }
  }

  dispatch(action:string,value?:any){
    this.state.next({type:action,value});
  }
  /* eslint-disable */
  storeCB(fn:any){
    for(let i in fn){
      this.fns[i] = fn[i];
    }
  }
  /* eslint-enable */




}
