<div *ngIf="showBrowserBindScreen" class="otp" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">
 <mat-card class="ib-browser-bind mar-t-100">
    <div  fxLayout="column" fxLayoutAlign="space-around center" > 
      <mat-icon svgIcon="ib_logo" class="ib_logo"></mat-icon>
      <h1 class="login-title ">Welcome to Metro Bank Online Banking</h1>
    </div>
    <div class="login-seperator"></div>
    <div class="ib-browser-form">
      <form #browserBindForm="ngForm" novalidate="" autocomplete="off" >
        <div class="mar-t-10">
          <div class="">
            <div class="">
              <h3 class="margin-0"><label class="user-label">{{msgTemplate}}</label></h3>
            </div>
            <div class="mar-t-10">
              <div class="full-width">
                  <div class="seeds-dialog">
                  {{descriptionTemplate}}
                  </div>
              </div>
            </div>
            <mat-card class="bind-info mar-t-20">
              <mat-icon svgIcon="ib_info-22" class="vertical-align-top ib-info-icon"></mat-icon>
              <span class="vertical-align-top ib-bind-info">Only trust personal browsers. Never trust a public or work browser.</span>
            </mat-card>
            <div class="mar-t-50" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between center">
                      <div class="mar-t-10" ngClass.xs="width-100 text-center">
                        <span *ngIf="transmitLoader" ngClass.xs="text-center">
                          <img class="btn-loader" alt="loading" [src]="env.loader">
                        </span>
                        <button mat-flat-button color="primary" class="continue"  ngClass.xs="width-100" type="submit" (click)="rememberBrowser(true)" aria-label="Trust this browser">Trust this browser</button>
                      </div>
              <div class="mar-b-40">
                <button  class="secondary-btn" (click)="rememberBrowser(false)" aria-label="Don't trust">Don't trust</button>
              </div>
          </div>
            </div>
        </div>
      </form>
    </div>
  </mat-card>
</div>